import "./style.scss";
import { Container, Pagination } from "react-bootstrap";
import {
  AutoSubmitProof,
  AutoSubmitProofQuery,
  AutoSubmitProofStatus,
  PaginatedQuery,
  PaginationResult,
  Round1InfoQuery,
  Round1Status,
  Round2InfoQuery,
} from "zkwasm-service-helper";
import {
  useGetAutoSubmitProofsQuery,
  useGetRound1InfosQuery,
  useGetRound2InfosQuery,
} from "../../data/apiSlice";
import { useState } from "react";
import { Link } from "react-router-dom";
import { PaginationJump } from "../PaginationAmount/pagination-jump";
import { useAppSelector } from "../../app/hooks";
import { selectConfig } from "../../data/statusSlice";

// Since the display for each type of batch is similar, we can just use the same component for all of them.
export type BatchProofTypeString = "AutoSubmitProof" | "Round1" | "Round2";

interface BatchProofListProps {
  batchProofType: BatchProofTypeString;
}

const AutoSubmitStatusStyle = (status: AutoSubmitProofStatus) => {
  switch (status) {
    case AutoSubmitProofStatus.Pending:
      return "batch-status-pending";
    case AutoSubmitProofStatus.Batched:
      return "batch-status-batched";
  }
};

const Round1StatusStyle = (status: Round1Status) => {
  switch (status) {
    case Round1Status.Pending:
      return "batch-status-pending";
    case Round1Status.Batched:
      return "batch-status-batched";
  }
};

export const Round1Table = () => {
  const appConfig = useAppSelector(selectConfig);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const maxTasksPerPage = 5;

  const autoSubmitProofQuery: PaginatedQuery<AutoSubmitProofQuery> = {
    total: maxTasksPerPage,
    start: currentPage * maxTasksPerPage - maxTasksPerPage,
  };
  const {
    data: autoSubmitProofs,
    error: r1error,
    isLoading: r1isLoading,
  } = useGetAutoSubmitProofsQuery(autoSubmitProofQuery);
  const totalQueryCount = autoSubmitProofs?.total || 0;

  const handlePagination = function (page: number) {
    if (page < 1 || page > Math.ceil(totalQueryCount / maxTasksPerPage)) return;

    setCurrentPage(page);
  };

  return (
    <>
      <table className="main-table mt-2">
        <thead>
          <tr>
            <th scope="col">Proof Task ID</th>
            <th scope="col">Batch Status</th>
            <th scope="col">Target Proof Submitted</th>
            <th scope="col">Network</th>
            <th scope="col">Batch Finished</th>
          </tr>
        </thead>
        <tbody>
          {autoSubmitProofs?.data.map((batchProof, index) => {
            let style = AutoSubmitStatusStyle(batchProof.status);
            let network = batchProof.auto_submit_network_chain_id;
            let chainName =
              appConfig.chain_info_list.find((d: any) => d.chain_id == network)
                ?.chain_name || "Unsupported Network";
            return (
              <tr key={"r1" + index}>
                <td scope="row" className="td-address">
                  <Link to={`/task/${batchProof.task_id}`}>
                    {batchProof.task_id}
                  </Link>
                </td>
                <td className={"td-info py-2 " + style}>{batchProof.status}</td>
                <td className="td-info">
                  {new Date(batchProof.batch_started!).toLocaleString()}
                </td>
                <td className="td-info">{chainName}</td>
                <td className="td-info">
                  {batchProof.batch_finished
                    ? new Date(batchProof.batch_finished).toLocaleString()
                    : "Not Finished"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination-wrapper">
        {/* Helper component to jump to a particular page */}
        <PaginationJump
          totalQueryCount={totalQueryCount}
          maxTasksPerPage={maxTasksPerPage}
          setCurrentPage={setCurrentPage}
        ></PaginationJump>

        <Pagination className="justify-content-center">
          <Pagination.First onClick={() => handlePagination(1)} />
          <Pagination.Prev onClick={() => handlePagination(currentPage - 1)} />

          <Pagination.Item active>
            {currentPage} of {Math.ceil(totalQueryCount / maxTasksPerPage)}
          </Pagination.Item>

          <Pagination.Next onClick={() => handlePagination(currentPage + 1)} />
          <Pagination.Last
            onClick={() =>
              handlePagination(Math.ceil(totalQueryCount / maxTasksPerPage))
            }
          />
        </Pagination>
      </div>
    </>
  );
};

export const Round2Table = () => {
  const appConfig = useAppSelector(selectConfig);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const maxTasksPerPage = 5;

  const r1query: PaginatedQuery<Round1InfoQuery> = {
    total: maxTasksPerPage,
    start: currentPage * maxTasksPerPage - maxTasksPerPage,
  };
  const {
    data: r1data,
    error,
    isLoading,
    isSuccess,
  } = useGetRound1InfosQuery(r1query);
  const totalQueryCount = r1data?.total || 0;
  const handlePagination = function (page: number) {
    if (page < 1 || page > Math.ceil(totalQueryCount / maxTasksPerPage)) return;

    setCurrentPage(page);
  };
  return (
    <>
      <table className="main-table mt-2">
        <thead>
          <tr>
            <th scope="col">Round 1 Proof ID</th>

            <th scope="col">Batch Status</th>
            <th scope="col">Target Proof Submitted</th>
            <th scope="col">Network</th>
            <th scope="col">Batch Finished</th>
          </tr>
        </thead>
        <tbody>
          {isSuccess &&
            r1data?.data.map((batchProof, index) => {
              let style = Round1StatusStyle(batchProof.status);

              const chainName =
                appConfig.chain_info_list.find(
                  (d: any) =>
                    d.chain_id == batchProof.verifier_contracts.chain_id,
                )?.chain_name || "Unsupported Network";
              return (
                <tr key={"r2" + index}>
                  {/* Round 2 Data is just the output of Round 1 */}
                  <td scope="row" className="td-address">
                    <Link to={`/batchproof/round2/${batchProof._id["$oid"]}`}>
                      {batchProof._id["$oid"]}
                    </Link>
                  </td>

                  <td className={"td-info py-2 " + style}>
                    {batchProof.status}
                  </td>
                  <td className="td-info">
                    {new Date(batchProof.batch_started!).toLocaleString()}
                  </td>
                  <td className="td-info">{chainName}</td>
                  <td className="td-info">
                    {batchProof.batch_finished
                      ? new Date(batchProof.batch_finished).toLocaleString()
                      : "Not Finished"}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className="pagination-wrapper">
        <PaginationJump
          totalQueryCount={totalQueryCount}
          maxTasksPerPage={maxTasksPerPage}
          setCurrentPage={setCurrentPage}
        ></PaginationJump>
        <Pagination className="justify-content-center">
          <Pagination.First onClick={() => handlePagination(1)} />
          <Pagination.Prev onClick={() => handlePagination(currentPage - 1)} />

          <Pagination.Item active>
            {currentPage} of {Math.ceil(totalQueryCount / maxTasksPerPage)}
          </Pagination.Item>

          <Pagination.Next onClick={() => handlePagination(currentPage + 1)} />
          <Pagination.Last
            onClick={() =>
              handlePagination(Math.ceil(totalQueryCount / maxTasksPerPage))
            }
          />
        </Pagination>
      </div>
    </>
  );
};

export const FinalTable = () => {
  const appConfig = useAppSelector(selectConfig);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const maxTasksPerPage = 5;

  const r2query: PaginatedQuery<Round2InfoQuery> = {
    total: maxTasksPerPage,
    start: currentPage * maxTasksPerPage - maxTasksPerPage,
  };
  const {
    data: r2data,
    error,
    isLoading,
    isSuccess,
  } = useGetRound2InfosQuery(r2query);
  const totalQueryCount = r2data?.total || 0;

  const handlePagination = function (page: number) {
    if (page < 1 || page > Math.ceil(totalQueryCount / maxTasksPerPage)) return;

    setCurrentPage(page);
  };
  return (
    <>
      <table className="main-table mt-2">
        <thead>
          <tr>
            <th scope="col">Round 2 Proof ID</th>
            <th scope="col">Batch Finished At</th>
            <th scope="col">Aggregator Verifier </th>
          </tr>
        </thead>
        <tbody>
          {isSuccess &&
            r2data?.data.map((batchProof, index) => {
              const chainId = batchProof.verifier_contracts.chain_id;

              const chainName =
                appConfig.chain_info_list.find(
                  (d: any) => d.chain_id == chainId,
                )?.chain_name || "Unsupported Network";

              return (
                <tr key={"final" + index}>
                  <td scope="row" className="td-address">
                    <Link to={`/batchproof/final/${batchProof._id["$oid"]}`}>
                      {batchProof._id["$oid"]}
                    </Link>
                  </td>

                  <td className="td-info py-2">
                    {new Date(batchProof.batched_time!).toLocaleString()}
                  </td>
                  <td className="td-info">
                    {chainName} -{" "}
                    {batchProof.verifier_contracts.aggregator_verifier}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className="pagination-wrapper">
        <PaginationJump
          totalQueryCount={totalQueryCount}
          maxTasksPerPage={maxTasksPerPage}
          setCurrentPage={setCurrentPage}
        ></PaginationJump>
        <Pagination className="justify-content-center">
          <Pagination.First onClick={() => handlePagination(1)} />
          <Pagination.Prev onClick={() => handlePagination(currentPage - 1)} />

          <Pagination.Item active>
            {currentPage} of {Math.ceil(totalQueryCount / maxTasksPerPage)}
          </Pagination.Item>

          <Pagination.Next onClick={() => handlePagination(currentPage + 1)} />
          <Pagination.Last
            onClick={() =>
              handlePagination(Math.ceil(totalQueryCount / maxTasksPerPage))
            }
          />
        </Pagination>
      </div>
    </>
  );
};
