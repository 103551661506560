import React, { useState, useEffect } from "react";
import "bootswatch/dist/slate/bootstrap.min.css";
import {
  Button,
  Container,
  Form,
  Col,
  Row,
  Pagination,
  FormGroup,
  Spinner,
} from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectReceipts } from "../../layout/layoutSlice";
import { QueryParams } from "zkwasm-service-helper";
import {
  useLazyGetNodeInfoQuery,
  useLazyGetTaskListQuery,
} from "../../data/apiSlice";
import {
  selectL1Account,
  loadUser,
  loadSubscription,
} from "../../data/accountSlice";
import Statistics from "../../components/Statistics";
import CustomCard from "../../components/Card";
import { Link } from "react-router-dom";
import { addressAbbreviation } from "../../utils/address";
import { Time } from "../../utils/inputs";
import { getLineType } from "../../utils/style";
import { PaginationJump } from "../../components/PaginationAmount/pagination-jump";
import {
  FinalTable,
  Round1Table,
  Round2Table,
} from "../../components/BatchProofList";

const taskTypes = [
  {
    name: "All",
    link: "/",
  },
  {
    name: "Setup",
    link: "/setup",
  },
  {
    name: "Prove",
    link: "/prove",
  },
  {
    name: "Deploy",
    link: "/deploy",
  },
];
const taskStatuses = ["All", "Pending", "Processing", "Done", "Fail"];
const INTERVAL_ONE_SEC = 1000;
const TASK_LIST_POLLING_INTERVAL_MSEC = 60 * INTERVAL_ONE_SEC;
const MAX_TASKS_PER_PAGE = 5;
const TOO_MANY_REQUESTS_ERR_CODE = "Too many requests";

function handleUnknownError(error: unknown): string {
  if (error instanceof Error) {
    return error.message;
  } else if (typeof error === "object" && error !== null) {
    return JSON.stringify(error);
  } else {
    return String(error);
  }
}

const useInitLoad = (): boolean => {
  const [isInitLoad, setIsInitLoad] = useState(true);
  useEffect(() => {
    if (isInitLoad) {
      setIsInitLoad(false);
    }
  }, [isInitLoad]);
  return isInitLoad;
};

export default function HomePage() {
  const dispatch = useAppDispatch();
  const receipts = useAppSelector(selectReceipts);
  const account = useAppSelector(selectL1Account);

  const [typeFilter, setTypeFilter] = useState<string>("All");
  const [statusFilter, setStatusFilter] = useState<string>("All");
  const pageSizeOptions = [10, 25, 50];
  const [maxTasksPerPage, _] = React.useState<number>(pageSizeOptions[0]);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  let [query, setQuery] = useState<QueryParams>({
    user_address: "",
    md5: "",
    id: "",
    tasktype: "",
    taskstatus: "",
    start: maxTasksPerPage * (currentPage - 1),
    total: maxTasksPerPage,
  });
  const [queryString, setQueryString] = useState("");
  const [queryType, setQueryType] = useState<string>("md5"); //default to md5 search

  const handlePagination = function (page: number) {
    if (page < 1 || page > Math.ceil(totalQueryCount / maxTasksPerPage)) return;

    setCurrentPage(page);
    //Refetch tasks with new page number (in useEffect on query change)
    setQuery({ ...query, start: maxTasksPerPage * (page - 1) });
  };

  const handleSearch = (searchString: string) => {
    //Parse the search string to determine whether input is an address or an md5 hash
    setQueryString(searchString);
    if (searchString.length === 42 && searchString.startsWith("0x")) {
      console.log("address", searchString);
      return setQueryType("user_address");
    }
    if (searchString.length === 32) {
      console.log("md5", searchString);
      return setQueryType("md5");
    }
    if (searchString.length === 24) {
      console.log("id", searchString);
      return setQueryType("id");
    }
    //Reset query type if search string is empty and cause refetch
    if (searchString.length === 0) {
      setQuery((prev) => {
        return { ...prev, user_address: "", md5: "", id: "" };
      });
    } else {
      //If search string is not an address or an md5 hash or ObjectID, set to md5
      //but do not refetch
      setQueryType("md5");
    }
  };

  function search() {
    //Search will ignore the tasktype and status filter
    let customQuery = {
      user_address: "",
      md5: "",
      id: "",
      tasktype: "",
      taskstatus: "",
    };
    customQuery[queryType as keyof typeof customQuery] = queryString;

    let q = {
      ...customQuery,
      start: 0,
      total: maxTasksPerPage,
    };
    if (
      (queryType === "user_address" && q.user_address === query.user_address) ||
      (queryType === "md5" && q.md5 === query.md5) ||
      (queryType === "id" && q.id === query.id)
    ) {
      return;
    }
    setCurrentPage(1);
    setQuery(q);
  }

  const [
    triggerTasksData,
    {
      data: tasksData,
      isLoading: loadingTasks,
      isSuccess,
      isError: loadingError,
      error: loadingErrorInfo,
    },
  ] = useLazyGetTaskListQuery({
    pollingInterval: TASK_LIST_POLLING_INTERVAL_MSEC,
  });
  const { data: tasks, total: totalQueryCount } =
    isSuccess && tasksData ? tasksData : { data: [], total: 0 };

  const [triggerSetupTaskData, { data: setupResponse }] =
    useLazyGetTaskListQuery();
  const setups = setupResponse?.data || [];

  const [triggerProofTaskData, { data: provesResponse }] =
    useLazyGetTaskListQuery();
  const proves = provesResponse?.data || [];

  const [triggerNodeStats, nodeStats] = useLazyGetNodeInfoQuery();

  const executeQueries = async () => {
    triggerSetupTaskData({
      user_address: "",
      md5: "",
      id: "",
      tasktype: "Setup",
      taskstatus: "",
      start: 0,
      total: MAX_TASKS_PER_PAGE,
    });
    triggerProofTaskData({
      user_address: "",
      md5: "",
      id: "",
      tasktype: "Prove",
      taskstatus: "",
      start: 0,
      total: MAX_TASKS_PER_PAGE,
    });
    triggerTasksData(query);
    triggerNodeStats({ start: 0, total: MAX_TASKS_PER_PAGE });
    dispatch(loadUser());
  };

  const isInitLoad = useInitLoad();
  useEffect(() => {
    // when receipts updates, send a refetch to update the tasks after
    const timer = setTimeout(async () => {
      if (isInitLoad) {
        return;
      }

      triggerTasksData(query);
      dispatch(loadUser());
    }, 5 * INTERVAL_ONE_SEC);
    return () => clearTimeout(timer);
  }, [receipts]);

  useEffect(() => {
    const executeTasksData = async () => {
      if (isInitLoad) {
        return;
      }
      triggerTasksData(query);
    };
    executeTasksData();
  }, [query]);

  useEffect(() => {
    dispatch(loadUser());
    dispatch(loadSubscription());
  }, [account]);

  useEffect(() => {
    if (isInitLoad) {
      executeQueries();
    }
  }, [isInitLoad]);

  return (
    <>
      <div className="img-bg "></div>
      <Container
        className="d-flex flex-column main-content position-relative px-0"
        style={{ zIndex: "1" }}
      >
        <div className="content-wrapper">
          <div className="d-flex align-items-center justify-content-between">
            <h1 className="h1 text-white m-0">The ZKWASM Task Explorer</h1>

            <div className="d-flex ">
              <FormGroup className="d-flex flex-column align-items-start me-2">
                {/* <Form.Label>Task Type:</Form.Label> */}
                <Form.Select
                  aria-label="Default select example"
                  size="sm"
                  value={typeFilter}
                  onChange={(e) => {
                    setTypeFilter(e.target.value);
                    setQuery((prev) => {
                      return {
                        ...prev,
                        tasktype:
                          e.target.value === "All" ? "" : e.target.value,
                      };
                    });
                  }}
                >
                  {taskTypes.map((t) => {
                    return (
                      <option value={t.name} key={t.name}>
                        {t.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </FormGroup>
              <FormGroup className="d-flex flex-column align-items-start">
                {/* <Form.Label>Task Status:</Form.Label> */}
                <Form.Select
                  aria-label="Default select example"
                  size="sm"
                  value={statusFilter}
                  className="custom-line"
                  onChange={(e) => {
                    setStatusFilter(e.target.value);
                    setQuery((prev) => {
                      return {
                        ...prev,
                        taskstatus:
                          e.target.value === "All" ? "" : e.target.value,
                      };
                    });
                  }}
                >
                  {taskStatuses.map((t) => {
                    return (
                      <option value={t} key={t}>
                        {t}
                      </option>
                    );
                  })}
                </Form.Select>
              </FormGroup>
            </div>
          </div>

          <Form className="d-flex px-0 mt-3 align-items-center">
            <Form.Control
              type="text"
              placeholder="Enter an MD5 hash, 0x address, or task ID"
              className="me-2 search-bar"
              id="queryMD5"
              aria-label="Search"
              onChange={(e) => handleSearch(e.target.value)}
            />
            <Button
              size="sm"
              className="search-btn "
              onClick={() => {
                search();
              }}
            >
              Search
            </Button>
          </Form>
        </div>
      </Container>

      <Statistics></Statistics>
      <Container className="main-list px-0 " style={{ paddingTop: "30px" }}>
        <Row className="mb-4">
          <Col>
            <CustomCard
              header="Latest Setups"
              headerProps={{
                className: "aqua",
              }}
            >
              {setups.map((task, index) => {
                if (index > 5) return;
                let hash = task.md5;
                return (
                  <div key={task._id["$oid"]} className="px-0 task-detail-row ">
                    <Row className="my-1">
                      <Col className="d-flex align-items-center justify-content-between task-latest-row">
                        <span className=" px-2 span-background-setup">
                          <span className="span-color-md5">MD5&nbsp;</span>
                          <Link
                            to={`/image/${hash}`}
                            className="link-color-hash"
                          >
                            {addressAbbreviation(hash, 6)}
                          </Link>
                        </span>
                        <div>
                          <Link to={`/user/${task.user_address}`}>
                            {addressAbbreviation(task.user_address, 4)}
                          </Link>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="d-flex align-items-center justify-content-between">
                        <span>
                          <span>TaskID </span>
                          <Link to={`/task/${task._id["$oid"]}`}>
                            {task._id["$oid"]}
                          </Link>
                        </span>
                        <span>
                          <Time timestr={task.submit_time}></Time>
                        </span>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </CustomCard>
          </Col>
          <Col>
            <CustomCard
              header="Latest Proofs"
              headerProps={{
                className: "light-blue",
              }}
            >
              {proves.map((task, index) => {
                if (index > 5) return;
                let hash = task.md5;
                return (
                  <div key={task._id["$oid"]} className="px-0 task-detail-row ">
                    <Row className="my-1">
                      <Col className="d-flex align-items-center justify-content-between task-latest-row">
                        <span className="px-2 span-background-proof">
                          <span className="span-color-md5">MD5&nbsp;</span>
                          <Link
                            to={`/image/${hash}`}
                            className="link-color-hash"
                          >
                            {addressAbbreviation(hash, 6)}
                          </Link>
                        </span>
                        <div>
                          <Link to={`/user/${task.user_address}`}>
                            {addressAbbreviation(task.user_address, 4)}
                          </Link>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="d-flex align-items-center justify-content-between">
                        <span>
                          <span>TaskID </span>
                          <Link to={`/task/${task._id["$oid"]}`}>
                            {task._id["$oid"]}
                          </Link>
                        </span>
                        <span>
                          <Time timestr={task.submit_time}></Time>
                        </span>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </CustomCard>
          </Col>
          {/* <Col>
                    <CustomCard
                      header="Latest Deployments"
                      headerProps={{
                        className: "lighter-blue",
                      }}
                    >
                      {deploys.map((task, index) => {
                        if (index > 5) return;
                        let hash = task.md5;
                        return (
                          <div
                            key={task._id["$oid"]}
                            className="px-0 task-detail-row "
                          >
                            <Row className="my-1">
                              <Col className="d-flex align-items-center justify-content-between task-latest-row">
                                <span className=" px-2 span-background-deploy">
                                  <span className="span-color-md5">
                                    MD5&nbsp;
                                  </span>
                                  <Link
                                    to={`/image/${hash}`}
                                    className="link-color-hash"
                                  >
                                    {addressAbbreviation(hash, 6)}
                                  </Link>
                                </span>
                                <div>
                                  <Link to={`/user/${task.user_address}`}>
                                    {addressAbbreviation(task.user_address, 4)}
                                  </Link>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="d-flex align-items-center justify-content-between">
                                <span>
                                  <span>TaskID </span>
                                  <Link to={`/task/${task._id["$oid"]}`}>
                                    {task._id["$oid"]}
                                  </Link>
                                </span>
                                <span>
                                  <Time timestr={task.submit_time}></Time>
                                </span>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                    </CustomCard>
                  </Col> */}
        </Row>
        <Container className="px-0">
          <h5 className="table-heading ">Prover List</h5>
          {nodeStats.isLoading ? (
            <>Loading Stats</>
          ) : (
            <>
              <table className="main-table mt-2">
                <thead>
                  <tr>
                    <th scope="col">Top Node Addresses</th>
                    <th scope="col">Successful Tasks</th>
                    <th scope="col">Failed Tasks</th>
                    <th scope="col">Total Tasks</th>
                    <th scope="col">Last Proof Time</th>
                    <th scope="col">Last Proof Timestamp</th>
                  </tr>
                </thead>
                <tbody>
                  {nodeStats.data?.data?.map((node) => (
                    <tr key={node.address}>
                      <td
                        scope="row"
                        className="td-address"
                        style={{ padding: "8px 0" }}
                      >
                        <a href={`/node?address=${node.address}`}>
                          {node.address}
                        </a>
                      </td>
                      <td className="td-info">
                        {node.statistics?.successful_tasks || 0}
                      </td>
                      <td className="td-info">
                        {node.statistics?.failed_tasks || 0}
                      </td>
                      <td className="td-info">
                        {node.statistics?.total_tasks || 0}
                      </td>
                      <td className="td-info">
                        {node.statistics?.proof_timing_stats?.latest_time_taken_secs?.toFixed(
                          4,
                        ) || "N/A"}
                      </td>
                      <td className="td-info">
                        {node.statistics?.proof_timing_stats?.latest_timestamp
                          ? new Date(
                              node.statistics.proof_timing_stats?.latest_timestamp,
                            ).toLocaleString()
                          : "N/A"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="pagination-wrapper">
                <a className="more-button me-4" href="/nodes">
                  More
                </a>
              </div>
            </>
          )}
        </Container>
        <Container className="table-container px-0">
          <h5 className="table-heading ">Task History</h5>
          {loadingTasks ? (
            <>
              <Row className="justify-content-center my-4">
                <Spinner></Spinner>
              </Row>
              <Row className="justify-content-center my-4">
                <div className="w-auto">Loading tasks...</div>
              </Row>
            </>
          ) : (
            <>
              <table className="main-table mt-2">
                <thead>
                  <tr>
                    <th scope="col">Task Id</th>
                    <th scope="col">Application Image</th>
                    <th scope="col">Published By</th>
                    <th scope="col">Type</th>
                    <th scope="col">Submit At</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {tasks.map((task, _) => {
                    return (
                      <tr key={task._id["$oid"]}>
                        <td scope="row" className="td-address text-left">
                          <Link to={`task/${task._id["$oid"]}`}>
                            {task._id["$oid"]}
                          </Link>
                        </td>
                        <td className="min-width-lg">
                          <Link to={`image/${task.md5}`}>{task.md5}</Link>
                        </td>
                        <td className="td-address min-width-md">
                          <Link to={`/user/${task.user_address}`}>
                            {addressAbbreviation(task.user_address, 4)}
                          </Link>
                        </td>
                        <td className="td-info">{task.task_type}</td>

                        <td className="td-info min-width-md">
                          <Time timestr={task.submit_time}></Time>
                        </td>
                        <td className="min-width-sm">
                          <div
                            className={`task-${getLineType(task.status)}-badge`}
                          >
                            {task.status}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {/* TODO: Remake pagination without bootstrap */}
              <div className="pagination-wrapper">
                <PaginationJump
                  totalQueryCount={totalQueryCount}
                  maxTasksPerPage={maxTasksPerPage}
                  setCurrentPage={handlePagination}
                ></PaginationJump>
                <Pagination className="justify-content-center">
                  <Pagination.First onClick={() => handlePagination(1)} />
                  <Pagination.Prev
                    onClick={() => handlePagination(currentPage - 1)}
                  />

                  <Pagination.Item active>
                    {currentPage} of{" "}
                    {Math.ceil(totalQueryCount / maxTasksPerPage)}
                  </Pagination.Item>

                  <Pagination.Next
                    onClick={() => handlePagination(currentPage + 1)}
                  />
                  <Pagination.Last
                    onClick={() =>
                      handlePagination(
                        Math.ceil(totalQueryCount / maxTasksPerPage),
                      )
                    }
                  />
                  {/* <PaginationAmount
                      pageSizeOptions={pageSizeOptions}
                      onPageSizeChange={handlePageSizeChange}
                    ></PaginationAmount> */}
                </Pagination>
              </div>
            </>
          )}
          {loadingError &&
            handleUnknownError(loadingErrorInfo).includes(
              TOO_MANY_REQUESTS_ERR_CODE,
            ) && (
              <Row className="justify-content-center my-4">
                Could not fetch tasks, please try again later.
              </Row>
            )}
        </Container>

        <h5 className="table-heading ">Auto Submit Proof Task History</h5>
        <Round1Table />
        <h5 className="table-heading">Round 1 Proof History</h5>
        <Round2Table />
        <h5 className="table-heading ">Round 2 Proof History</h5>
        <FinalTable />
      </Container>
    </>
  );
}
