import { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { zkWASMApi } from "../data/apiSlice";
import { Link } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import {
  loginL1AccountAsync,
  selectL1Account,
  selectUserInfo,
  selectSubscription,
} from "../data/accountSlice";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  ToastContainer,
  Toast,
} from "react-bootstrap";
import { Receipts, selectReceipts } from "./layoutSlice";
import { Task } from "zkwasm-service-helper";
import { ProofInfoModal } from "../modals/proofInfo";
import { ImageInfoModal } from "../modals/imageInfo";
import { NewWASMImage } from "../modals/addNewImage";
import { NewProveTask } from "../modals/addNewProveTask";
import AddCustomURL from "../modals/addCustomURL";
import {
  addressAbbreviation,
  getWalletState,
  setWalletState,
} from "../utils/address";
import logo from "../images/logo.png";
import Footer from "./Foot";
import ImageDetail from "../components/ImageDetail";
import TaskDetail from "../components/TaskDetail";
import UserDetail from "../components/UserDetail";
import InstructionTable from "../components/InstructionTable";
import HomePage from "../components/HomePage";
import WalletIcon from "../assets/imgs/wallet_icon.png";
import TickCircle from "../images/icons/tick_circle.png";
import UpArrow from "../images/icons/up_arrow.png";
import {
  updateCurrentEndpoint,
  selectCurrentEndpoint,
  Endpoint,
  selectEndpointList,
  defaultEndpoint,
  setEndpointList,
} from "../data/endpoint";
import "bootswatch/dist/slate/bootstrap.min.css";
import NodeList from "../components/NodeList";
import OnlineNodesSummaryPage from "../components/OnlineNodesSummaryPage";

import Subscription from "../components/Subscriptions";
import {
  FinalBatchProofDetail,
  Round2BatchProofDetail,
} from "../components/BatchProofDetail";
import NodeDetail from "../components/NodeDetail";

// custom styles must be imported after all other imports to override any matching css with our css.
import "./style.scss";
import { loadConfig } from "../data/statusSlice";

function MainNavBar() {
  const dispatch = useAppDispatch();
  let account = useAppSelector(selectL1Account);
  let userInfo = useAppSelector(selectUserInfo);
  let subscriptionInfo = useAppSelector(selectSubscription);
  const endpoints = useAppSelector(selectEndpointList);
  const currentEndpoint = useAppSelector(selectCurrentEndpoint);

  const changeProvider = (endpoint: Endpoint) => {
    dispatch(updateCurrentEndpoint(endpoint));

    dispatch(zkWASMApi.util.resetApiState());
  };

  const handleRemoveEndpoint = (nickname: string) => {
    const newEndpoints = endpoints.filter(
      (endpoint) => endpoint.nickname !== nickname,
    );
    dispatch(setEndpointList(newEndpoints));
    //set current endpoint to default if it is removed
    if (currentEndpoint.nickname === nickname) {
      changeProvider(defaultEndpoint);
    }
  };

  useEffect(() => {
    // Try auto connect if account is already connected
    const walletState = getWalletState();

    if (walletState?.connected) {
      dispatch(loginL1AccountAsync());
    }
  }, []);

  useEffect(() => {
    // Update wallet state when account changes
    if (account) {
      setWalletState({ connected: true });
    } else {
      setWalletState({ connected: false });
    }
  }, [account]);

  useEffect(() => {
    dispatch(loadConfig());
  }, [currentEndpoint]);

  return (
    <Navbar expand="lg" style={{ zIndex: "1000" }} className="nav-text">
      <Container className="nav-container">
        <Navbar.Brand href="/" className="border-0 ps-0" target="_self">
          <img
            src={logo}
            alt=""
            style={{ width: "67px", height: "19px" }}
          ></img>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <div role="button" className="nav-link d-flex align-items-center">
              <NewWASMImage></NewWASMImage>
            </div>
            <div role="button" className="nav-link d-flex align-items-center">
              <NewProveTask></NewProveTask>
            </div>

            <NavDropdown
              title="Select PAAS Provider"
              id="basic-nav-dropdown"
              menuRole="menu"
              className="d-flex align-items-center background-none"
            >
              <NavDropdown.Item
                onClick={() => changeProvider(defaultEndpoint)}
                key={defaultEndpoint.url}
              >
                {defaultEndpoint.nickname}&nbsp;
                {currentEndpoint.nickname === defaultEndpoint.nickname
                  ? "(Selected)"
                  : ""}
              </NavDropdown.Item>
              {endpoints.map((endpoint) => {
                return (
                  <NavDropdown.Item
                    onClick={() => changeProvider(endpoint)}
                    key={endpoint.url}
                    className="d-flex align-items-center justify-content-between"
                  >
                    {endpoint.nickname}&nbsp;
                    {currentEndpoint.nickname === endpoint.nickname
                      ? "(Selected)"
                      : ""}
                    <i
                      className="bi bi-trash"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveEndpoint(endpoint.nickname);
                      }}
                    ></i>
                  </NavDropdown.Item>
                );
              })}
              <NavDropdown.Divider></NavDropdown.Divider>
              <NavDropdown.Item>
                <AddCustomURL></AddCustomURL>
              </NavDropdown.Item>
            </NavDropdown>
            {account && (
              <>
                <Link
                  to={`/user/${account.address}`}
                  className="account-info d-flex align-items-center"
                >
                  <i className="bi bi-person  circle-surround me-1"></i>
                  {addressAbbreviation(account.address, 4)}
                </Link>
                <div className="d-flex align-items-center">
                  {userInfo && (
                    <div className="balance-info">
                      <i className="bi bi-wallet me-0 ms-2"></i>
                      <span className="ms-1 font-weight-bold label">
                        Balance:{" "}
                      </span>
                      <span className="text-white amount">
                        &nbsp;
                        {BigInt(userInfo.credits).toString()} Credits
                      </span>
                      {userInfo.credit_deficit &&
                        BigInt(userInfo.credit_deficit) !== BigInt(0) && (
                          <span
                            className="amount"
                            style={{ color: "lightcoral", marginLeft: "5px" }}
                          >
                            &nbsp;
                            {BigInt(userInfo.credit_deficit).toString()} Deficit
                          </span>
                        )}
                    </div>
                  )}

                  {subscriptionInfo && (
                    <div className="subscription-info ms-2">
                      <img src={TickCircle}></img>
                      <span className="ms-1 font-weight-bold label">
                        Subscription:{" "}
                      </span>
                      <span className="text-white amount">
                        {subscriptionInfo.params.subscription_type}
                      </span>
                    </div>
                  )}

                  <span className="topup ms-2">
                    <Link className="topup-button" to="/subscribe">
                      <img src={UpArrow}></img>{" "}
                      <span className="ms-1">Charge</span>
                    </Link>
                  </span>
                </div>
              </>
            )}

            {!account && (
              <div
                onClick={() => dispatch(loginL1AccountAsync())}
                className="d-flex justify-content-center align-items-center ms-2"
              >
                <div className="connect-account">
                  <img
                    src={WalletIcon}
                    alt="wallet"
                    style={{ maxWidth: "12px" }}
                  />
                  <span className="ms-2">Connect Wallet</span>
                </div>
              </div>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

function ReceiptNavBar() {
  const receipts = useAppSelector(selectReceipts);
  const [showToasts, setShowToasts] = useState<Record<string, boolean>>({});

  useEffect(() => {
    setShowToasts((prevShowToasts) => {
      const newShowToasts: Record<string, boolean> = { ...prevShowToasts };

      receipts.forEach((receipt: Receipts) => {
        if (prevShowToasts[receipt.id] === undefined)
          newShowToasts[receipt.id] = true;
      });

      return newShowToasts;
    });
  }, [receipts]);

  return (
    <>
      <ToastContainer position="top-end" className="p-3">
        {receipts.map((receipt) => {
          console.log(receipt);
          return (
            <Toast
              key={receipt.id}
              bg="success"
              onClose={() =>
                setShowToasts((prev) => ({ ...prev, [receipt.id]: false }))
              }
              show={showToasts[receipt.id]}
              delay={5000}
              autohide
              animation={true}
            >
              <Toast.Header className="justify-content-between">
                New Task!
              </Toast.Header>
              <Toast.Body>
                Task <Link to={`/task/${receipt.id}`}>{receipt.id}</Link>{" "}
                created for wasm application image{" "}
                <Link to={`/image/${receipt.md5}`}>{receipt.md5}</Link>
              </Toast.Body>
            </Toast>
          );
        })}
      </ToastContainer>
    </>
  );
}

export interface MoreInfoProps {
  task: Task;
}

export function MoreInfoModal(info: MoreInfoProps) {
  let task = info.task;
  if (task.task_type === "Setup" || task.task_type === "Reset") {
    return <ImageInfoModal md5={info.task.md5}></ImageInfoModal>;
  } else if (task.task_type === "Deploy") {
    return <ImageInfoModal md5={info.task.md5}></ImageInfoModal>;
  } else if (task.task_type === "Prove") {
    return <ProofInfoModal task={info.task}></ProofInfoModal>;
  } else {
    return <>NA</>;
  }
}

export function Main() {
  return (
    <>
      <MainNavBar></MainNavBar>
      <ReceiptNavBar></ReceiptNavBar>
      <Routes>
        <Route path="/" element={<HomePage></HomePage>}></Route>
        <Route
          path="/subscribe"
          element={<Subscription></Subscription>}
        ></Route>
        <Route path="/image/:md5" element={<ImageDetail></ImageDetail>}></Route>
        <Route
          path="/batchproof/round2/:taskId"
          element={<Round2BatchProofDetail />}
        ></Route>
        <Route
          path="/batchproof/final/:taskId"
          element={<FinalBatchProofDetail />}
        ></Route>
        <Route path="/task/:taskId" element={<TaskDetail></TaskDetail>}></Route>
        <Route path="/nodes" element={<NodeList></NodeList>}></Route>
        <Route path="/node" element={<NodeDetail></NodeDetail>}></Route>
        <Route
          path="/user/:userAddress"
          element={<UserDetail></UserDetail>}
        ></Route>
        <Route
          path="/table"
          element={<InstructionTable></InstructionTable>}
        ></Route>
        <Route
          path="/online_nodes_summary"
          element={<OnlineNodesSummaryPage></OnlineNodesSummaryPage>}
        ></Route>
      </Routes>
      <Footer></Footer>
    </>
  );
}
