import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { Subscription, User } from "zkwasm-service-helper";
import { withBrowserConnector } from "web3subscriber/src/client";
import { DelphinusBrowserConnector } from "web3subscriber/src/provider";

export interface L1AccountInfo {
  address: string;
  chainId: string;
}

async function loginL1Account(): Promise<L1AccountInfo> {
  return await withBrowserConnector(
    async (connector: DelphinusBrowserConnector) => {
      let i = await connector.getJsonRpcSigner();
      let accountInfo: L1AccountInfo = {
        address: await i.getAddress(),
        chainId: (await connector.getNetworkId()).toString(),
      };

      return accountInfo;
    },
  );
}

export interface AccountState {
  l1Account?: L1AccountInfo;
  status: "Loading" | "Ready";
  userInfo?: User;
  subscriptionInfo?: Subscription | null;
}

export interface State {
  account: AccountState;
}

const initialState: AccountState = {
  status: "Loading",
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const loginL1AccountAsync = createAsyncThunk(
  "account/fetchAccount",
  async (thunkApi) => {
    let account = await loginL1Account();
    return account;
  },
);

export const loadUser = createAsyncThunk(
  "account/fetchUser",
  async (_, thunkApi) => {
    let state = thunkApi.getState() as RootState;
    let address = state.account.l1Account?.address;
    if (!address) {
      return undefined;
    }
    let helper = state.endpoint.zkWasmServiceHelper;
    let user = await helper.queryUser({ user_address: address });
    return user;
  },
);

export const loadSubscription = createAsyncThunk(
  "account/fetchSubscription",
  async (_, thunkApi) => {
    let state = thunkApi.getState() as RootState;
    let address = state.account.l1Account?.address;
    if (!address) {
      return undefined;
    }
    let helper = state.endpoint.zkWasmServiceHelper;
    let subscription = await helper.queryUserSubscription({
      user_address: address,
    });
    return subscription;
  },
);

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setL1Account: (state, account) => {
      state.l1Account!.address = account.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginL1AccountAsync.pending, (state) => {
        state.status = "Loading";
      })
      .addCase(loginL1AccountAsync.fulfilled, (state, c) => {
        state.status = "Ready";
        state.l1Account = c.payload;
      })
      .addCase(loadUser.fulfilled, (state, c) => {
        state.userInfo = c.payload;
      })
      .addCase(loadSubscription.fulfilled, (state, c) => {
        state.subscriptionInfo = c.payload;
      });
  },
});

export const selectL1Account = <T extends State>(state: T) =>
  state.account.l1Account;
export const selectLoginStatus = <T extends State>(state: T) =>
  state.account.status;
export const selectUserInfo = <T extends State>(state: T) =>
  state.account.userInfo;

export const selectSubscription = <T extends State>(state: T) =>
  state.account.subscriptionInfo;

export default accountSlice.reducer;
