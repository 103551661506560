import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { Container, Form, FormSelect } from "react-bootstrap";
import { TextInput } from "../components/Inputs";
import { selectConfig } from "../data/statusSlice";
import {
  EthersError,
  ethers,
  formatUnits,
  isError,
  parseEther,
  parseUnits,
} from "ethers";
import { ModalCommon, ModalCommonProps, ModalStatus } from "./base";
import { selectZkWasmServiceHelper } from "../data/endpoint";
import { addressAbbreviation } from "../utils/address";
import { withBrowserConnector } from "web3subscriber/src/client";
import { DelphinusBrowserConnector } from "web3subscriber/src/provider";
import { SubscriptionParams, ZkWasmUtil } from "zkwasm-service-helper";
import Dropdown from "../components/Dropdown";
import { calculateEndDate, calculateExtendedDate } from "../utils/amount";
import { loadSubscription, loadUser } from "../data/accountSlice";
interface SubscriptionProps {
  subscriptionInfo: SubscriptionParams;
  onClose?: () => void;
}

export default function Subscribe(subscriptionProps: SubscriptionProps) {
  console.log(subscriptionProps);
  const dispatch = useAppDispatch();
  const { subscriptionInfo } = subscriptionProps;
  console.log(subscriptionInfo);
  const zkwasmHelper = useAppSelector(selectZkWasmServiceHelper);
  const appConfig = useAppSelector(selectConfig);
  const account = useAppSelector((state) => state.account);

  const [amount, setAmount] = useState<BigInt>(
    BigInt(subscriptionInfo.price_per_base_duration),
  );
  const baseNumberOfDays =
    subscriptionInfo.duration.base_duration === "Month" ? 30 : 1;

  const isDifferentPlan =
    account.subscriptionInfo &&
    account.subscriptionInfo.params.subscription_type !==
      subscriptionInfo.subscription_type;

  const [transactionHash, setTransactionHash] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [withTxHash, setWithTxHash] = useState<boolean>(false);
  const [durationMultiplier, setDurationMultiplier] = useState<number>(1);
  const [modalStatus, setModalStatus] = useState<ModalStatus>(
    ModalStatus.PreConfirm,
  );
  const selectedDuration = {
    base_duration: subscriptionInfo.duration.base_duration,
    multiplier: durationMultiplier,
  };
  const [inProgress, setInProgress] = useState<boolean>(false);

  //Pop wallet in browser and save txhash
  const addPayment = async () => {
    // TODO: If user is subscribed, just return for now until upgrade/downgrade implemented somehow
    setInProgress(true);
    //maybe let user choose target address in UI, otherwise we can set the address depending on the node
    //or we must wait for the node to return a valid address

    //with txhash option checked
    if (withTxHash) {
      await submitSubscription(transactionHash);
      setInProgress(false);
      return;
    }
    let txhash = await trySubscribe();
    if (!txhash) return;
    await submitSubscription(txhash);
    setInProgress(false);
  };

  const trySubscribe = async () => {
    console.log(amount);
    let receiverAddress = appConfig.receiver_address;

    if (receiverAddress) {
      try {
        // Try Switch networks
        try {
          console.log("Switching networks");
          await withBrowserConnector(
            async (connector: DelphinusBrowserConnector) => {
              await connector.switchNet(
                "0x" + subscriptionInfo.token_params.network_id.toString(16),
              );
            },
          );
        } catch (e) {
          console.log(e);
          throw e;
        }
        // Submit ERC-20 transfer transaction and get tx hash to submit to server
        let txHash = await withBrowserConnector(
          async (connector: DelphinusBrowserConnector) => {
            const account = await connector.getJsonRpcSigner();

            const parsedAmount = amount;

            setMessage(
              `Waiting for transaction to ${addressAbbreviation(
                receiverAddress,
                4,
              )} for amount ${formatUnits(
                amount.toString(),
                subscriptionInfo.token_data.decimals,
              )} USDT`,
            );

            // todo: get from config
            const USDT_ADDRESS = subscriptionInfo.token_params.token_address;

            const contract = ZkWasmUtil.ERC20Contract(USDT_ADDRESS, account);

            const tx = await contract.transfer(receiverAddress, parsedAmount);

            setMessage("Waiting for confirmation: " + tx.hash);

            await tx.wait();
            return tx.hash;
          },
        );
        return txHash;
      } catch (e) {
        console.log(e, "error");
        let errMessage = e as EthersError;
        setMessage("Deposit failed with error: " + errMessage.shortMessage);
      }
    }
  };

  const submitSubscription = async (txHash: string) => {
    try {
      const userAddress = (await withBrowserConnector(
        async (connector: DelphinusBrowserConnector) => {
          return (await connector.getJsonRpcSigner()).address;
        },
      )) as string;
      //Send the transaction hash to the backend
      // todo: use real params
      await zkwasmHelper.addSubscription({
        subscriber_address: userAddress,
        subscription_type: subscriptionInfo.subscription_type,
        duration: selectedDuration,
        payment_hash: txHash,
      });

      setMessage(
        "Transaction successfully sent to server with hash: " + txHash,
      );
      setModalStatus(ModalStatus.Confirmed);
      setEndDate(
        isDifferentPlan
          ? calculateEndDate(selectedDuration)
          : calculateExtendedDate(
              account.subscriptionInfo?.end_date || Date.now() / 1000,
              selectedDuration,
            ),
      );
      dispatch(loadUser());
      dispatch(loadSubscription());
    } catch (error: any) {
      console.log(error);
      setModalStatus(ModalStatus.PreConfirm);
      setMessage(error.message);
      setInProgress(false);
    }
  };

  let content = (
    <>
      <Container>
        {isDifferentPlan && (
          <div className="alert alert-warning" role="alert">
            You are currently subscribed to a different plan. Subscribing to
            this plan will cancel your current subscription with no refund.
            Added credits remain in your account.
          </div>
        )}
        <Form className="subscription-info">
          <Form.Group className="position-relative ">
            <Form.Label variant="dark" className="payment-target ">
              Subscription Plan:
            </Form.Label>
            <Form.Text>{subscriptionInfo.subscription_type}</Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label variant="dark" className="payment-target ">
              User address:
            </Form.Label>
            <Form.Text>{account.l1Account?.address}</Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label variant="dark" className="payment-target ">
              Subscription End Date:{" "}
            </Form.Label>
            <Form.Text>
              {isDifferentPlan
                ? calculateEndDate(selectedDuration)
                : calculateExtendedDate(
                    account.subscriptionInfo?.end_date || Date.now() / 1000,
                    selectedDuration,
                  )}
            </Form.Text>
          </Form.Group>
          <Form.Group className="d-flex  me-2">
            <Form.Label>Duration:</Form.Label>
            {modalStatus === ModalStatus.Confirmed ? (
              <>
                <Form.Text>{durationMultiplier * baseNumberOfDays}</Form.Text>
              </>
            ) : (
              <Form.Select
                aria-label="Days"
                size="sm"
                disabled={inProgress}
                onChange={(e) => {
                  setDurationMultiplier(parseInt(e.target.value));
                  setAmount(
                    BigInt(subscriptionInfo.price_per_base_duration) *
                      BigInt(parseInt(e.target.value)),
                  );
                }}
              >
                {[1, 2, 3, 6, 12].map((t) => {
                  return (
                    <option value={t} key={t}>
                      {t * baseNumberOfDays}
                    </option>
                  );
                })}
              </Form.Select>
            )}
            <Form.Text className="ms-2">/ Days</Form.Text>
          </Form.Group>
          <Form.Group className="mb-3 d-flex ">
            <Form.Label variant="dark">
              <span>Amount: </span>
            </Form.Label>
            <Form.Text className="mt-0">
              {" "}
              {formatUnits(
                amount.toString(),
                subscriptionInfo.token_data.decimals,
              )}{" "}
              {subscriptionInfo.token_data.symbol}
            </Form.Text>
            <div className="ms-auto">
              <Form.Group
                className="d-flex ms-auto"
                onChange={() => {
                  setWithTxHash(!withTxHash);
                }}
              >
                <div className="ms-auto d-flex ">
                  <Form.Check
                    className="me-2"
                    disabled={inProgress}
                  ></Form.Check>
                  <span className="checkbox-label">Use a transaction hash</span>
                </div>
              </Form.Group>
            </div>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label variant="dark">Transaction Hash: </Form.Label>
            <TextInput
              name="txhash"
              type="text"
              placeholder="Submit a deposit transaction hash"
              multiple={false}
              value={transactionHash}
              disabled={!withTxHash || inProgress}
              onChange={(e) => {
                setTransactionHash(e.target.value);
                //might want to apply a minimum send amount here and check in UI
                if (e.target.value.length === 66) {
                  setMessage("");
                  return;
                }
              }}
            ></TextInput>
          </Form.Group>
        </Form>
      </Container>
    </>
  );

  const [endDate, setEndDate] = useState<string>("");

  const successContent = (
    <>
      <Container>
        <Form className="subscription-info">
          <Form.Group className="position-relative ">
            <Form.Label variant="dark" className="payment-target ">
              Subscription Plan:
            </Form.Label>
            <Form.Text>{subscriptionInfo.subscription_type}</Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label variant="dark" className="payment-target ">
              User address:
            </Form.Label>
            <Form.Text>{account.l1Account?.address}</Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label variant="dark" className="payment-target ">
              Subscription End Date:{" "}
            </Form.Label>
            <Form.Text>{endDate}</Form.Text>
          </Form.Group>
          <Form.Group className="d-flex">
            <Form.Label>Duration:</Form.Label>
            <Form.Text>{durationMultiplier * baseNumberOfDays}</Form.Text>
            <Form.Text className="ms-2">/ Days</Form.Text>
          </Form.Group>
          <Form.Group className="mb-3 d-flex ">
            <Form.Label variant="dark">
              <span>Amount: </span>
            </Form.Label>
            <Form.Text className="mt-0">
              {" "}
              {formatUnits(
                amount.toString(),
                subscriptionInfo.token_data.decimals,
              )}{" "}
              {subscriptionInfo.token_data.symbol}
            </Form.Text>
          </Form.Group>
        </Form>
      </Container>
    </>
  );

  const getButtonLabel = () => {
    if (!account.subscriptionInfo) {
      return "Subscribe";
    }
    if (
      account.subscriptionInfo.params.subscription_type ===
      subscriptionInfo.subscription_type
    ) {
      return "Extend";
    }
    return "Switch";
  };
  let props: ModalCommonProps = {
    btnLabel: (
      <>
        <div className="subscribe-button">
          <span>{getButtonLabel()}</span>
        </div>
      </>
    ),
    title:
      modalStatus === ModalStatus.Confirmed
        ? "Subscription Success"
        : "Subscription Info",
    childrenClass: "",
    handleConfirm: function (): void {
      addPayment();
    },
    handleClose: () => {
      setTransactionHash("");
      setWithTxHash(false);
      setMessage("");
      setInProgress(false);
      setModalStatus(ModalStatus.PreConfirm);
      setEndDate("");
      setDurationMultiplier(1);
      setAmount(
        BigInt(subscriptionProps.subscriptionInfo.price_per_base_duration),
      );
      subscriptionProps.onClose && subscriptionProps.onClose();
    },
    children: modalStatus === ModalStatus.Confirmed ? successContent : content,
    valid:
      (withTxHash && transactionHash.length === 66 && !inProgress) ||
      (!withTxHash && !inProgress),
    message: message,
    status: modalStatus,
    confirmLabel: "Confirm",
  };
  return ModalCommon(props);
}
