import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
} from "react-bootstrap";
import AddPayment from "./TopUp";
import TickPNG from "../../images/icons/tick.png";
import "./style.scss";
import GreenTickIcon from "../GreenTick";
import { useEffect, useState } from "react";
import { SubscriptionParams } from "zkwasm-service-helper";
import { EthersError, formatUnits, parseEther, parseUnits } from "ethers";
import { useAppSelector } from "../../app/hooks";
import { selectConfig } from "../../data/statusSlice";
import Subscribe from "../../modals/subscription";
import ContactUs from "../../modals/contact";
import SubscriptionInfoData from "./subscription_content.json";
import { getSubscriptionCredits, getSubscriptionPrice } from "./utils";

export default function SubscriptionMain() {
  const [currentTab, setCurrentTab] = useState("subscribe"); // Default tab

  useEffect(() => {
    // Get tab from URL if available
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    // Update state based on URL, default to "subscribe"
    setCurrentTab(tab || "subscribe");
  }, []); // This effect runs only once when the component mounts

  const updateTabAndUrl = (tab: string) => {
    // Update URL query parameter without reloading the page
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?tab=${tab}`,
    );

    // Update state to re-render component
    setCurrentTab(tab);
  };

  return (
    <Container
      style={{
        maxWidth: "1450px",
        minHeight: "80vh",
      }}
    >
      <Row className="subscription-page-header">
        <h1 className="page-title">Price and Subscription</h1>{" "}
        <small className="page-description">
          Choose the right plan based on your needs
        </small>
      </Row>
      {/* Tabs */}

      <div className="payment-tabs">
        <div
          className={`payment-tab ${currentTab === "topup" ? "active" : ""}`}
          onClick={() => updateTabAndUrl("topup")}
        >
          Top Up
        </div>
        <div
          className={`payment-tab ${
            currentTab === "subscribe" ? "active" : ""
          }`}
          onClick={() => updateTabAndUrl("subscribe")}
        >
          Subscribe
        </div>
      </div>

      {/* add payment as well */}
      {currentTab === "subscribe" && <SubscriptionContent />}
      {currentTab === "topup" && <AddPayment />}
    </Container>
  );
}

type ContentData = {
  subscription_type: string;
  subheading: string;
  included_content: string[];
  excluded_content: string[];
};

function SubscriptionContent() {
  const [active, setActive] = useState("monthly");
  const handleSwitch = (active: string) => {
    setActive(active);
  };

  const appConfig = useAppSelector(selectConfig);

  if (appConfig.subscription_plans.length === 0) {
    return <div className="text-center">No subscription plans available</div>;
  }

  const subscriptionInfoBasic: SubscriptionParams | undefined =
    appConfig.subscription_plans[0];
  const subscriptionInfoDeveloper: SubscriptionParams | undefined =
    appConfig.subscription_plans[1];

  const subscriptionInfoEnterprise: SubscriptionParams = {
    subscription_type: "Enterprise",
    price_per_base_duration: "",
    token_params: {
      topup_conversion_rate: null,
      token_address: "0x",
      network_id: 0,
    },
    credited_amount: "0",
    duration: {
      base_duration: "Month",
      multiplier: 0,
    },
    token_data: {
      symbol: "Zk",
      decimals: 18,
    },
    enabled: false,
  };

  const BasicSubscriptionData = SubscriptionInfoData[0] as ContentData;

  const DeveloperSubscriptionData = SubscriptionInfoData[1] as ContentData;

  const EnterpriseSubscriptionData = SubscriptionInfoData[2] as ContentData;

  return (
    <>
      <Row className="gx-0 notice mb-4">
        Upgrade to our subscription for enhanced features
      </Row>

      {/* <MonthlyYearlySwitch
        active={active}
        onSwitch={handleSwitch}
      ></MonthlyYearlySwitch> */}
      {appConfig.subscription_plans.length === 0 ? (
        <div className="text-center">No subscription plans available</div>
      ) : (
        <Row className="mt-4 justify-content-evenly">
          <Col lg={3} md={6} sm={12}>
            <SubscriptionDetail
              currentState={active}
              subscriptionInfo={subscriptionInfoBasic}
              contentData={BasicSubscriptionData}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <SubscriptionDetail
              currentState={active}
              subscriptionInfo={subscriptionInfoDeveloper}
              contentData={DeveloperSubscriptionData}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <SubscriptionDetail
              currentState={active}
              subscriptionInfo={subscriptionInfoEnterprise}
              contentData={EnterpriseSubscriptionData}
            />
          </Col>
          {/* <Col lg={3} md={6} sm={12}>
          <SubscriptionDetail currentState={active} />
        </Col>
        <Col lg={3} md={6} sm={12}>
          <SubscriptionDetail currentState={active} />
        </Col> */}
        </Row>
      )}

      <Row className="notice mt-5 mb-2">
        <span
          style={{
            fontWeight: "500",
            fontSize: "16px",
          }}
        >
          Compare all features
        </span>
      </Row>
      <ComparisonTable></ComparisonTable>
    </>
  );
}

function ComparisonTable() {
  const appConfig = useAppSelector(selectConfig);
  const [active, setActive] = useState("monthly");
  const handleSwitch = (active: string) => {
    setActive(active);
  };

  const subscriptionInfoBasic: SubscriptionParams | undefined =
    appConfig.subscription_plans[0];
  const subscriptionInfoDeveloper: SubscriptionParams | undefined =
    appConfig.subscription_plans[1];

  return (
    <table className="main-table subscription-table">
      <thead>
        <tr>
          <th
            style={{
              width: "28%",
            }}
          >
            {/* <div className="table-header">
              <Row className="mb-2">Choose payment frequency</Row>
              <MonthlyYearlySwitch
                active={active}
                onSwitch={handleSwitch}
              ></MonthlyYearlySwitch>
            </div> */}
          </th>
          <th>
            <div className="table-header">
              <Row className="price-type">Basic</Row>
              <Row className="price-amount">
                <span>$10 USDT for 30 Days</span>
              </Row>
            </div>
          </th>
          <th>
            <div className="table-header">
              <Row className="price-type">Developer</Row>
              <Row className="price-amount">
                <span>$20 USDT for 30 Days</span>
              </Row>
            </div>
          </th>
          <th>
            <div className="table-header">
              <Row className="price-type">Enterpise</Row>
              <Row className="price-amount">
                <span>Contact us for pricing</span>
              </Row>
            </div>
          </th>
        </tr>
      </thead>

      <tbody>
        {/* list features to compare */}
        <tr>
          <td>
            <div className="subsection-header">Proving Service</div>
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>
            <span className="subsection-info">Access to proof services</span>
          </td>
          <td>
            <GreenTickIcon />
          </td>
          <td>
            <GreenTickIcon />
          </td>
          <td>
            <GreenTickIcon />
          </td>
        </tr>
        <tr>
          <td>
            <span className="subsection-info">
              Access to proof verification
            </span>
          </td>
          <td>
            <GreenTickIcon />
          </td>
          <td>
            <GreenTickIcon />
          </td>
          <td>
            <GreenTickIcon />
          </td>
        </tr>
        <tr>
          <td>
            <span className="subsection-info">Credits provided per period</span>
          </td>
          <td>{getSubscriptionCredits(subscriptionInfoBasic)}</td>
          <td>{getSubscriptionCredits(subscriptionInfoDeveloper)}</td>
          <td>Custom for enterprise use</td>
        </tr>

        <tr>
          <td>
            <div className="subsection-header">Proof Task Specific</div>
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>
            <span className="subsection-info">Maximum Debug Logs</span>
          </td>
          <td>0 KB</td>
          <td>1 KB</td>
          <td>Custom for enterprise use</td>
        </tr>
        <tr>
          <td>
            <span className="subsection-info">Maximum Context Size</span>
          </td>
          <td>0 KB</td>
          <td>4 KB</td>
          <td>Custom for enterprise use</td>
        </tr>
      </tbody>
      <tfoot className="table-footer">
        <tr>
          <td></td>
          <td>
            <div className="button-wrapper">
              <Subscribe subscriptionInfo={subscriptionInfoBasic}></Subscribe>
            </div>
          </td>
          <td>
            <div className="button-wrapper">
              <Subscribe
                subscriptionInfo={subscriptionInfoDeveloper}
              ></Subscribe>
            </div>
          </td>
          <td>
            <div className="button-wrapper">
              <button className="subscribe-button">Contact Us</button>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  );
}

interface DetailProps {
  currentState: string;
  subscriptionInfo: SubscriptionParams;
  contentData?: ContentData;
}

function SubscriptionDetail(props: DetailProps) {
  // todo: function to handle the different durations
  const durationValue =
    props.currentState === "monthly" ? "for 30 days" : "per year";

  const subscriptionInfo: SubscriptionParams = props.subscriptionInfo;

  return (
    <Card className="subscription-info-card">
      <CardHeader>
        <Row>
          <h5>
            {props.subscriptionInfo.subscription_type ||
              props.contentData?.subscription_type}
          </h5>
          <span className="description">{props.contentData?.subheading}</span>
        </Row>
      </CardHeader>
      <CardBody>
        {subscriptionInfo.price_per_base_duration !== "" ? (
          <Row>
            <div className="price">
              <span className="h3">
                {formatUnits(
                  subscriptionInfo.price_per_base_duration,
                  subscriptionInfo.token_data.decimals,
                )}{" "}
                {subscriptionInfo.token_data.symbol}
              </span>
              <span className="duration">{durationValue}</span>
            </div>

            <Col className="w-auto"></Col>
          </Row>
        ) : (
          <Row>
            <div className="price">
              <span className="h3">Contact us for pricing</span>
              {/* <span className="duration">Contact us </span> */}
            </div>
          </Row>
        )}
        <Row>
          {props.contentData &&
            props.contentData.included_content.length > 0 &&
            props.contentData.included_content.map((content, index) => {
              return (
                <span key={index} className="included-items">
                  <img src={TickPNG} alt="" />
                  {content}
                </span>
              );
            })}
        </Row>
      </CardBody>
      <CardFooter>
        {subscriptionInfo.subscription_type === "Enterprise" ? (
          <>
            <div className="button-wrapper">
              <ContactUs />
            </div>
          </>
        ) : (
          <>
            <Subscribe subscriptionInfo={subscriptionInfo}></Subscribe>
          </>
        )}
      </CardFooter>
    </Card>
  );
}

interface SwitchProps {
  active: string;
  onSwitch: (active: string) => void;
}
function MonthlyYearlySwitch(props: SwitchProps) {
  const [active, setActive] = useState(props.active);

  return (
    <Row>
      <Col>
        <div className="duration-switch">
          <span
            onClick={() => {
              setActive("monthly");
              props.onSwitch("monthly");
            }}
            className={`duration-button ${
              active === "monthly" ? "active" : ""
            }`}
          >
            Monthly
          </span>
          <span
            onClick={() => {
              setActive("yearly");
              props.onSwitch("yearly");
            }}
            className={`duration-button ${active === "yearly" ? "active" : ""}`}
          >
            Yearly
          </span>
        </div>
      </Col>
    </Row>
  );
}
