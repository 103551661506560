import React, { useEffect } from "react";
import "./style.scss";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectL1Account } from "../../data/accountSlice";
import { getLineType } from "../../utils/style";
import {
  useGetTaskListQuery,
  useGetUserInfoQuery,
  useGetDepositHistoryQuery,
  useGetUserSubscriptionQuery,
} from "../../data/apiSlice";
import {
  Card,
  Container,
  Row,
  Col,
  Spinner,
  Tabs,
  Tab,
  Pagination,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Time } from "../../utils/inputs";

import { formatUnits, parseUnits } from "ethers";
import { selectConfig } from "../../data/statusSlice";
import UpArrow from "../../images/icons/up_arrow.png";
import Calendar from "../../images/icons/calendar.png";
export default function UserDetail() {
  const appConfig = useAppSelector(selectConfig);
  const [currentTab, setCurrentTab] = React.useState<string>("tasks");
  const pageSizeOptions = [10, 25, 50];
  const [maxTasksPerPage, setMaxTasksPerPage] = React.useState<number>(
    pageSizeOptions[0],
  );
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [paginationArray, setPaginationArray] = React.useState<any[]>([]);
  const [paginationTotal, setPaginationTotal] = React.useState<number>(0);

  const handlePageSizeChange = (pageSize: number) => {
    setMaxTasksPerPage(pageSize);
    if (currentPage > Math.floor(paginationTotal / pageSize + 1)) {
      setCurrentPage(1);
    }
  };

  const account = useAppSelector(selectL1Account);
  const dispatch = useAppDispatch();
  const { userAddress } = useParams<{ userAddress: string }>();
  const {
    data: userTasks,
    isLoading: loadingTasks,
    isSuccess,
    isError: taskError,
    refetch: refetchTasks,
  } = useGetTaskListQuery({
    id: "",
    user_address: userAddress!,
    md5: "",
    tasktype: "",
    taskstatus: "",
    start: maxTasksPerPage * (currentPage - 1),
    total: maxTasksPerPage,
  });

  const { data: tasks, total: totalTasks } = isSuccess
    ? userTasks
    : { data: [], total: 0 };

  const {
    data: userInfo,
    isLoading: loadingUserInfo,
    isSuccess: userInfoSuccess,
    isError: userInfoError,
    refetch,
  } = useGetUserInfoQuery({
    user_address: userAddress!,
  });

  const { data: subscriptionInfo } = useGetUserSubscriptionQuery({
    user_address: userAddress!,
  });

  const handlePagination = (page: number): void => {
    if (
      !paginationArray ||
      page < 1 ||
      page > Math.ceil(paginationTotal / maxTasksPerPage)
    ) {
      return;
    }
    setCurrentPage(page);
  };

  //TODO: Transaction history, deposit/withdrawals to zkWASM server

  const {
    data: txHistory,
    isLoading: loadingTxHistory,
    isSuccess: txHistorySuccess,
    isError: txHistoryError,
    refetch: refetchHistory,
  } = useGetDepositHistoryQuery({
    user_address: userAddress!,
  });

  const { data: txs, total: totalTxs } = txHistorySuccess
    ? txHistory
    : { data: [], total: 0 };

  useEffect(() => {
    if (currentTab === "tasks") {
      setPaginationArray(tasks);
      setPaginationTotal(totalTasks);
    } else if (currentTab === "transactions") {
      setPaginationArray(txHistory ? txHistory.data : []);
      setPaginationTotal(totalTxs);
    }
  }, [currentTab, txHistorySuccess, isSuccess]);
  console.log(userInfo, "USERINFO");
  console.log(subscriptionInfo, "SUBSCRIPTIONINFO");
  return (
    <>
      {isSuccess ? (
        <>
          <Container className="mt-4">
            <div className="overview-container">
              <Row className="mb-2 pb-2 justify-content-around ">
                <Col>
                  <div className="d-flex flex-row align-items-center justify-content-between user-header">
                    <span className="">User &nbsp;</span>
                    <div className="d-flex align-items-center user-address">
                      <span className="me-2 image-hash">{userAddress}</span>
                      <a
                        href={`https://www.etherscan.io/address/${userAddress}`}
                        target="_blank"
                      >
                        <i
                          className="bi bi-box-arrow-up-right"
                          role="View on Etherscan"
                        ></i>
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mt-1 ">
                <div className="d-flex ">
                  <Col
                    className=" info-holder d-flex align-items-center"
                    style={{ maxWidth: "40%", marginRight: "20px" }}
                  >
                    <span className="info-header">
                      <i className="bi bi-wallet me-4"></i> Balance
                    </span>

                    <span className="info-amount">
                      {userInfo ? BigInt(userInfo.credits).toString() : 0}{" "}
                      Credits
                    </span>
                    {account?.address.toLowerCase() ===
                      userAddress?.toLowerCase() && (
                      <span className="topup ms-2">
                        <Link
                          className="topup-button"
                          to="/subscribe?tab=topup"
                        >
                          <i className="bi bi-plus-circle"></i>{" "}
                          <span className="ms-1">Top up</span>
                        </Link>
                      </span>
                    )}
                  </Col>
                  <Col
                    className=" info-holder d-flex align-items-center"
                    style={{ maxWidth: "40%", marginRight: "20px" }}
                  >
                    <span className="info-header">
                      <img src={Calendar} alt="sub_icon" className="me-2" />
                      Current Subscription
                    </span>

                    <span className="info-amount">
                      {subscriptionInfo
                        ? subscriptionInfo.params.subscription_type
                        : "None"}
                    </span>

                    {account?.address.toLowerCase() ===
                      userAddress?.toLowerCase() && (
                      <span className="topup ms-2">
                        <Link
                          className="topup-button"
                          to="/subscribe?tab=subscribe"
                        >
                          <img src={UpArrow}></img>{" "}
                          <span className="ms-1">Manage</span>
                        </Link>
                      </span>
                    )}
                  </Col>
                  <Col className="info-holder d-flex align-items-center">
                    <span className="info-header">
                      <i className="bi bi-stack me-4"></i> Total Tasks Submitted
                    </span>
                    <span className="info-amount">
                      {totalTasks} Tasks submitted
                    </span>
                  </Col>
                </div>
              </Row>
            </div>
          </Container>

          <>
            <Container
              className="table-container"
              style={{ marginTop: "30px" }}
            >
              <Tabs
                defaultActiveKey="tasks"
                id="fill-tab-example"
                className="mb-3 tag-group"
                activeKey={currentTab}
                onSelect={(key) => {
                  setCurrentPage(1);
                  setCurrentTab(key!);
                }}
              >
                <Tab title="Tasks" eventKey="tasks" tabClassName=" tab-button">
                  {tasks.length > 0 ? (
                    <>
                      <table
                        className="user-table main-table"
                        style={{ marginTop: "10px" }}
                      >
                        <thead className="thead-dark">
                          <tr>
                            <th scope="col">Task Id</th>
                            <th scope="col">Application Image</th>
                            <th scope="col">Type</th>
                            <th scope="col">Submitted At</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>

                        <tbody>
                          {tasks.map((t, index) => {
                            // if (
                            //   index < (currentPage - 1) * maxTasksPerPage ||
                            //   index > currentPage * maxTasksPerPage - 1
                            // )
                            //   return null;
                            return (
                              <React.Fragment key={t._id["$oid"]}>
                                <tr>
                                  <td className="table-row-info td-address">
                                    <Link to={`/task/${t._id["$oid"]}`}>
                                      {t._id["$oid"]}
                                    </Link>
                                  </td>
                                  <td className="table-row-info">
                                    <Link to={`/image/${t.md5}`}>{t.md5}</Link>
                                  </td>
                                  <td className="td-info">{t.task_type}</td>
                                  <td className="td-info ">
                                    <Time timestr={t.submit_time}></Time>
                                  </td>
                                  <td className="min-width">
                                    <div
                                      className={`task-${getLineType(
                                        t.status,
                                      )}-badge `}
                                    >
                                      {t.status}
                                    </div>
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          })}
                        </tbody>
                      </table>
                      <div className="pagination-wrapper">
                        <Pagination className="justify-content-center">
                          <Pagination.First
                            onClick={() => handlePagination(1)}
                          />
                          <Pagination.Prev
                            onClick={() => handlePagination(currentPage - 1)}
                          />
                          <Pagination.Item active>
                            {currentPage} of{" "}
                            {Math.ceil(paginationTotal / maxTasksPerPage)}
                          </Pagination.Item>
                          <Pagination.Next
                            onClick={() => handlePagination(currentPage + 1)}
                          />
                          <Pagination.Last
                            onClick={() =>
                              handlePagination(
                                Math.ceil(paginationTotal / maxTasksPerPage),
                              )
                            }
                          />
                          {/* <PaginationAmount
                    pageSizeOptions={pageSizeOptions}
                    onPageSizeChange={handlePageSizeChange}
                  ></PaginationAmount> */}
                        </Pagination>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-center">
                        No tasks found for the current address.
                      </div>
                    </>
                  )}
                </Tab>
                <Tab
                  title="Transactions"
                  eventKey="transactions"
                  tabClassName="ms-2 tab-button"
                >
                  {txHistorySuccess ? (
                    txs.length > 0 ? (
                      <>
                        <table
                          className="main-table tx-table"
                          style={{ marginTop: "10px" }}
                        >
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col">Transaction Hash</th>
                              <th scope="col">Chain</th>
                              <th scope="col">Amount</th>
                              <th scope="col">To</th>
                            </tr>
                          </thead>
                          <tbody>
                            {txs.map((t, index) => {
                              if (
                                index < (currentPage - 1) * maxTasksPerPage ||
                                index > currentPage * maxTasksPerPage - 1
                              )
                                return null;

                              //TODO: Add chain name and chain configuration information if needed
                              //If variable deposit address is used, the address should be fetched from the backend
                              return (
                                <React.Fragment key={index}>
                                  <tr className="text-white ">
                                    <td
                                      className="table-row-info "
                                      style={{ height: "40px" }}
                                    >
                                      <Link
                                        to={`https://sepolia.etherscan.io/tx/${t.txhash}`}
                                        target="_blank"
                                        //className="tx-hash"
                                      >
                                        {t.txhash}
                                      </Link>
                                    </td>
                                    <td>{t.token_params.network_id}</td>
                                    <td className="table-row-info">
                                      {formatUnits(
                                        t.amount,
                                        t.token_data.decimals,
                                      ).toString()}{" "}
                                      {t.token_data.symbol}
                                    </td>
                                    <td>{t.receiver_address}</td>
                                  </tr>
                                </React.Fragment>
                              );
                            })}
                          </tbody>
                        </table>
                        <div className="pagination-wrapper">
                          <Pagination className="justify-content-center">
                            <Pagination.First
                              onClick={() => handlePagination(1)}
                            />
                            <Pagination.Prev
                              onClick={() => handlePagination(currentPage - 1)}
                            />
                            <Pagination.Item active>
                              {currentPage} of{" "}
                              {Math.ceil(paginationTotal / maxTasksPerPage)}
                            </Pagination.Item>
                            <Pagination.Next
                              onClick={() => handlePagination(currentPage + 1)}
                            />
                            <Pagination.Last
                              onClick={() =>
                                handlePagination(
                                  Math.ceil(paginationTotal / maxTasksPerPage),
                                )
                              }
                            />
                            {/* <PaginationAmount
                    pageSizeOptions={pageSizeOptions}
                    onPageSizeChange={handlePageSizeChange}
                  ></PaginationAmount> */}
                          </Pagination>
                        </div>
                      </>
                    ) : (
                      <div className="text-center">
                        No transactions found for the current address.
                      </div>
                    )
                  ) : (
                    <>
                      {loadingTxHistory && (
                        <>
                          <Spinner></Spinner>
                        </>
                      )}
                      {txHistoryError && (
                        <>
                          <span>Error loading transaction history</span>
                        </>
                      )}
                    </>
                  )}
                </Tab>
              </Tabs>
            </Container>
          </>
        </>
      ) : (
        <>
          {loadingTasks && (
            <Container className="loading-screen d-flex justify-content-center align-items-center">
              <Row>
                <Col>
                  <Spinner></Spinner>
                </Col>
              </Row>
            </Container>
          )}
          {taskError && (
            <Container className="loading-screen d-flex justify-content-center align-items-center">
              <Row>
                <Col>
                  Error loading user information. Please try again later.
                </Col>
              </Row>
            </Container>
          )}
        </>
      )}
    </>
  );
}
