import React from "react";
import "./style.scss";

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  handleOutsideClick: () => void;
}

// Function to hide dropdown when clicked outside

function useOutsideAlerter(
  ref: React.RefObject<HTMLDivElement>,
  callback: () => void,
) {
  React.useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
const Dropdown = (props: IProps) => {
  let { children, handleOutsideClick, ...rest } = props;
  // hide dropdown when clicked outside
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  useOutsideAlerter(wrapperRef, handleOutsideClick);

  return (
    <>
      <div
        className="position-absolute bg-white w-100 border-box text-dark rounded overlay overflow-auto"
        ref={wrapperRef}
        {...rest}
      >
        {children}
      </div>
    </>
  );
};

export default Dropdown;
