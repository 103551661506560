import { withBrowserConnector } from "web3subscriber/src/client";
import { DelphinusBrowserConnector } from "web3subscriber/src/provider";
import { store } from "../app/store";

export function addressAbbreviation(address: string, tailLength: number) {
  return (
    address.substring(0, 8) +
    "..." +
    address.substring(address.length - tailLength, address.length)
  );
}

export async function signMessage(message: string) {
  let signature = await withBrowserConnector(
    async (connector: DelphinusBrowserConnector) => {
      const signature = connector.sign(message);
      return signature;
    },
  );
  return signature;
}

export async function switchNetwork(chainId: number) {
  let chainConfig = getChainConfigFromChainId(chainId);
  await withBrowserConnector(async (connector: DelphinusBrowserConnector) => {
    await connector.provider.send("wallet_switchEthereumChain", [
      { chainId: "0x" + chainConfig!.chain_id.toString(16) },
    ]);
  });
}

export function getChainConfigFromChainId(chainId: number) {
  let config = store.getState().status.config;

  let chainConfig = config.chain_info_list.find((c) => c.chain_id === chainId);
  if (!chainConfig) {
    return null;
  }
  return chainConfig;
}

type WalletState = {
  connected: boolean;
};

// Store if wallet is connected in local storage
export function getWalletState(): WalletState | null {
  let state = localStorage.getItem("wallet_state");
  if (state) {
    return JSON.parse(state);
  }
  return null;
}

export function setWalletState(state: WalletState) {
  localStorage.setItem("wallet_state", JSON.stringify(state));
}
