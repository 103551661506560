// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect } from "react";
import { Container, ListGroup, Form } from "react-bootstrap";
import { useAppSelector } from "../app/hooks";
import "./style.scss";
import { ModalCommon, ModalCommonProps, ModalStatus } from "./base";
import {
  Task,
  Image,
  ZkWasmUtil,
  VerifyProofParams,
} from "zkwasm-service-helper";
import { withBrowserConnector } from "web3subscriber/src/client";
import { DelphinusBrowserConnector } from "web3subscriber/src/provider";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { selectConfig } from "../data/statusSlice";
import { selectL1Account } from "../data/accountSlice";
import { selectZkWasmServiceHelper } from "../data/endpoint";
import { Inputs, capitalizeFirstLetter } from "../utils/inputs";
import Dropdown from "../components/Dropdown";
import { TextInput } from "../components/Inputs";
import { useGetImageByHashQuery } from "../data/apiSlice";
import { switchNetwork } from "../utils/address";
import BN from "bn.js";
export interface ProofInfoProps {
  task: Task;
}

export function ProofInfoModal(info: ProofInfoProps) {
  let account = useAppSelector(selectL1Account);
  let imageHelper = useAppSelector(selectZkWasmServiceHelper);
  const [message, setMessage] = React.useState("");
  const [disableButton, setDisableButton] = React.useState(false);
  const appConfig = useAppSelector(selectConfig);
  const [viewMode, setViewMode] = React.useState("proof"); // proof or verify
  const [selectedChainId, setSelectedChainId] = React.useState<number | null>(
    null,
  ); // proof or verify
  let image = useGetImageByHashQuery(info.task.md5!);
  const [showChainDropdown, setShowChainDropdown] = React.useState(false);

  const [status, setStatus] = React.useState<ModalStatus>(
    ModalStatus.PreConfirm,
  );

  let task = info.task;
  // TODO: update to use BigInt directly
  let aggregate_proof = ZkWasmUtil.bytesToBN(task.proof);
  let instances = ZkWasmUtil.bytesToBN(task.instances);
  let batchInstances = ZkWasmUtil.bytesToBN(task.batch_instances);
  let aux = ZkWasmUtil.bytesToBN(task.aux);

  async function testverify() {
    if (account) {
      setMessage("Verifying...");
      setDisableButton(true);
      setStatus(ModalStatus.PostConfirm);
      let image = (await getImageInfo(task.md5!)) as Image;
      let address = appConfig.deployments.find(
        (x) =>
          x.chain_id === selectedChainId &&
          x.circuit_size === image.circuit_size,
      )!.aggregator_verifier;

      try {
        await withBrowserConnector(
          async (connector: DelphinusBrowserConnector) => {
            let chainidhex = "0x" + selectedChainId!.toString(16);
            await connector.switchNet(chainidhex);
          },
        );
      } catch (e) {
        console.error(e);
        setMessage(
          "Error switching network, please add the network to your wallet",
        );
        setDisableButton(false);
        return;
      }

      await withBrowserConnector(
        async (connector: DelphinusBrowserConnector) => {
          try {
            let contract = await ZkWasmUtil.composeVerifyContract(
              connector,
              address,
            );

            console.log(
              "NETWORK ID",
              await connector.provider._detectNetwork(),
            );
            let proofParams: VerifyProofParams = {
              aggregate_proof: task.proof,
              verify_instance: task.shadow_instances,
              aux: task.aux,
              instances: [task.instances],
            };

            let tx = await ZkWasmUtil.verifyProof(
              contract.getEthersContract(),
              proofParams,
            );
            // wait for tx to be mined, can add no. of confirmations as arg
            await tx.wait();

            setMessage(
              "Verification transaction successful! Transaction hash: " +
                tx.hash,
            );
          } catch (e) {
            console.error(e);

            setMessage("Verification transaction unsuccessful");
            setDisableButton(false);
          }
        },
      );

      setDisableButton(false);
    } else {
      console.error("wallet not connected");
    }
  }

  async function getImageInfo(md5: string) {
    return await imageHelper.queryImage(md5);
  }
  let taskproof = (
    <>
      <Container>
        <Tabs defaultActiveKey="Inputs" className="mb-3 tab-group" justify>
          <Tab eventKey="Inputs" title="Inputs" tabClassName="tab-button">
            <p className="text-white">
              <span className="proof-info-label me-1">Public Inputs:</span>
              <Inputs inputs={task.public_inputs}></Inputs>
            </p>
            <span
              className="text-white"
              style={{
                minHeight: "100px",
              }}
            >
              <span className="proof-info-label me-1">Witness:</span>
              <Inputs inputs={task.private_inputs}></Inputs>
            </span>
          </Tab>
          <Tab
            eventKey="Instances"
            title="Instances"
            tabClassName="tab-button "
            className="text-white"
          >
            <div className="scroll-300">
              {instances.map((proof: BN, index) => {
                return (
                  <ListGroup.Item key={index}>
                    {ZkWasmUtil.bnToHexString(proof)}
                  </ListGroup.Item>
                );
              })}
            </div>
          </Tab>
          <Tab
            eventKey="BatchInstances"
            title="Batch Instances"
            tabClassName="tab-button "
            className="text-white"
          >
            <div className="scroll-300">
              {batchInstances.map((proof: BN, index) => {
                return (
                  <ListGroup.Item key={index}>
                    {ZkWasmUtil.bnToHexString(proof)}
                  </ListGroup.Item>
                );
              })}
            </div>
          </Tab>
          <Tab
            eventKey="prooftranscript"
            title="Proof Transcripts"
            tabClassName="tab-button"
            className="text-white"
          >
            <div className="scroll-300">
              {aggregate_proof.map((proof: BN, index) => {
                return (
                  <ListGroup.Item key={index}>
                    {ZkWasmUtil.bnToHexString(proof)}
                  </ListGroup.Item>
                );
              })}
            </div>
          </Tab>
          <Tab
            eventKey="auxdata"
            title="Aux Data"
            tabClassName="tab-button"
            className="text-white"
          >
            <div className="scroll-300">
              {aux.map((proof: BN, index) => {
                return (
                  <ListGroup.Item key={index}>
                    {ZkWasmUtil.bnToHexString(proof)}
                  </ListGroup.Item>
                );
              })}
            </div>
          </Tab>
        </Tabs>
      </Container>
    </>
  );

  let verifyOptions = (
    <>
      <Container className="position-relative " style={{ minHeight: "100px" }}>
        <Form.Label variant="dark">
          Verification Chain:{" "}
          <Form.Text className="text-muted ms-2">
            Select the chain to verify the proof on.
          </Form.Text>
        </Form.Label>
        <TextInput
          placeholder="Select a chain for Verification"
          autoComplete="off"
          value={capitalizeFirstLetter(
            selectedChainId
              ? appConfig.chain_info_list.find(
                  (d: any) => d.chain_id == selectedChainId,
                )!.chain_name
              : "",
          )}
          id="verification-chain"
          name="chain"
          type="text"
          multiple={false}
          readOnly
          // onChange={(e) => selectChain(e.target.value)}
          onClick={() => setShowChainDropdown(true)}
        ></TextInput>
        {showChainDropdown && image.isSuccess ? (
          <Dropdown handleOutsideClick={() => setShowChainDropdown(false)}>
            {appConfig.deployments.map((item, index) => {
              let chainInfo = appConfig.chain_info_list.find(
                (x) =>
                  x.chain_id === item.chain_id &&
                  item.circuit_size === image.data?.circuit_size,
              );
              if (chainInfo === undefined) return;

              return (
                <div
                  className="dropdown-option text-capitalize"
                  key={chainInfo!.chain_name + index}
                  onClick={() => {
                    setSelectedChainId(chainInfo!.chain_id);
                    setShowChainDropdown(false);
                    setDisableButton(false);
                  }}
                >
                  <span className="text-capitalize">
                    {chainInfo.chain_name}
                  </span>
                </div>
              );
            })}
          </Dropdown>
        ) : null}
      </Container>
    </>
  );

  let props: ModalCommonProps = {
    btnLabel: <i className="bi bi-eye"></i>,
    title: "Proof Information",
    childrenClass: "",
    modalClass: "",
    handleConfirm: function (): void {
      if (viewMode === "proof") {
        setViewMode("verify");
        setDisableButton(true);
        //setShowChainDropdown(true);
        return;
      }
      if (selectedChainId != null) {
        testverify();
      }
    },
    handleClose: () => {
      setMessage("");
      setSelectedChainId(null);
      setDisableButton(false);
      setStatus(ModalStatus.PreConfirm);
    },
    handleShow: () => {
      setViewMode("proof");
    },
    valid: !disableButton,
    status: status,
    children: viewMode === "proof" ? taskproof : verifyOptions,
    message: message,
    confirmLabel: viewMode === "proof" ? "Verify on chain" : "Confirm",
  };
  return ModalCommon(props);
}
