import "./style.scss";
import React from "react";
import { useParams, Link } from "react-router-dom";
import { Card, Container, Row, Col, Table, Spinner } from "react-bootstrap";
import { ProofInfoModal } from "../../modals/proofInfo";
import { ImageInfoModal } from "../../modals/imageInfo";
import { useGetTaskByIdQuery } from "../../data/apiSlice";

import { convertAmount, hexToAmount } from "../../utils/amount";
import TaskLogsModal from "../../modals/taskLogs";
import {
  AutoSubmitBatchMetadata,
  AutoSubmitStatus,
  CompressionType,
  ProofSubmitMode,
  ZkWasmUtil,
} from "zkwasm-service-helper";
import BN from "bn.js";
import { VerifyProofModal } from "../../modals/verifyProof";
import { hexlify } from "ethers";
import DownloadJsonButton from "./Download";
import { useAppSelector } from "../../app/hooks";
import { selectConfig } from "../../data/statusSlice";

export default function TaskDetail() {
  const { taskId } = useParams<{ taskId: string }>();
  const appConfig = useAppSelector(selectConfig);
  const {
    data: task,
    isLoading: loadingTask,
    isSuccess: loadedTask,
  } = useGetTaskByIdQuery({
    id: taskId!,
    user_address: "",
    md5: "",
    tasktype: "",
    taskstatus: "",
  });
  console.log(task, "task");

  const [processTime, setProcessTime] = React.useState(0);

  React.useEffect(() => {
    if (!task) return;
    if (task.process_started && task.process_finished) {
      setProcessTime(
        (new Date(Date.parse(task.process_finished)).getTime() -
          new Date(Date.parse(task.process_started)).getTime()) /
          1000,
      );
    }
  }, [task]);

  const [aggregate_proof, setAggregate_proof] = React.useState<BN[] | null>();
  const [single_proof, setSingle_proof] = React.useState<BN[] | null>();
  const [instances, setInstances] = React.useState<BN[] | null>();
  const [batchInstances, setBatchInstances] = React.useState<BN[] | null>();
  const [shadowInstances, setShadowInstances] = React.useState<BN[] | null>();
  const [aux, setAux] = React.useState<BN[] | null>();
  const [external_host_table, setExternal_host_table] = React.useState<
    BN[] | null
  >();
  const [input_context, setInput_context] = React.useState<BN[] | null>();
  const [output_context, setOutput_context] = React.useState<BN[] | null>();

  React.useEffect(() => {
    if (!task) return;
    if (task.proof) {
      setAggregate_proof(ZkWasmUtil.bytesToBN(task.proof));
    }
    if (task.single_proof) {
      setSingle_proof(ZkWasmUtil.bytesToBN(task.single_proof));
    }
    if (task.instances) {
      setInstances(ZkWasmUtil.bytesToBN(task.instances));
    }
    if (task.batch_instances) {
      setBatchInstances(ZkWasmUtil.bytesToBN(task.batch_instances));
    }
    if (task.shadow_instances) {
      setShadowInstances(ZkWasmUtil.bytesToBN(task.shadow_instances));
    }
    if (task.aux) {
      setAux(ZkWasmUtil.bytesToBN(task.aux));
    }
    if (task.external_host_table) {
      setExternal_host_table(ZkWasmUtil.bytesToBN(task.external_host_table));
    }
    if (task.input_context) {
      // split the byte array into 64 bit chunks
      setInput_context(ZkWasmUtil.bytesToBN(task.input_context, 8));
    }
    if (task.output_context) {
      setOutput_context(ZkWasmUtil.bytesToBN(task.output_context, 8));
    }
  }, [task]);

  const [showBatchDetailDropdown, setShowBatchDetailDropdown] =
    React.useState(false);

  const batchStatusMessage = (submit_status: AutoSubmitStatus) => {
    switch (submit_status) {
      case AutoSubmitStatus.RegisteredProof:
        return "Batch Completed and Proof Registered On-chain";
      case AutoSubmitStatus.Batched:
        return "Completed Final Batch Proof, Pending On-chain Registration";
      case AutoSubmitStatus.Failed:
        return "Batch Proof Failed";
      case AutoSubmitStatus.Round1:
        return "Waiting Round 1 Batch Proof";
      case AutoSubmitStatus.Round2:
        return "Round 1 Batch finished. Pending Round 2 Batch Proof";

      default:
        return "";
    }
  };

  const isBatchedProof = task?.proof_submit_mode === ProofSubmitMode.Auto;

  const isRegistered = () => {
    if (!task) return false;
    const batchProofData = task.batch_proof_data;
    if (!batchProofData) return false;
    const finalProofBatchIds = batchProofData.final_proof_batch_ids;
    if (!finalProofBatchIds) return false;
    return finalProofBatchIds.length > 0;
  };

  // For Manual Submit mode, show if the task is Done
  // For Auto Submit mode, show if the task is Done and the proof is registered
  const showVerifyModalButton =
    (task?.status === "Done" && !isBatchedProof) ||
    (task?.status === "Done" && isBatchedProof && isRegistered()) ||
    task?.status === "Stale";

  // Get the metadata values, e.g split by round2batchproofid_CHAINID
  const getRound2BatchProofIds = () => {
    if (!task) return [];
    let values = task.batch_proof_data?.round_2_batch_ids;

    return values || [];
  };

  const getFinalBatchProofIds = () => {
    if (!task) return [];
    let values = task.batch_proof_data?.final_proof_batch_ids;

    return values || [];
  };

  return (
    <>
      {task ? (
        <>
          <Container
            className="px-0 detail-max-width"
            style={{ marginTop: "40px" }}
          >
            <Row>
              <Col>
                <div className="py-1 d-flex align-items-center justify-content-between px-0 detail-header">
                  <div className="d-flex align-items-center">
                    <div className="d-flex flex-row align-items-end">
                      <span>Task ID</span>
                      <span>&nbsp; {taskId}</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="me-2 address">{task.user_address}</span>
                    <a
                      href={`https://www.etherscan.io/address/${task.user_address}`}
                      target="_blank"
                    >
                      <i
                        className="bi bi-box-arrow-up-right"
                        role="View on Etherscan"
                      ></i>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Container className="mt-5 " style={{ minHeight: "500px" }}>
            <Row className="task-detail detail-max-width">
              <Col>
                <Card className="tx-detail-body">
                  <Card.Header className="d-flex ">Task Overview</Card.Header>
                  {/* Task Properties */}
                  <Card.Body>
                    <Row className="py-1 g-0">
                      <Col className="col-sm-2">Application </Col>
                      <Col className="col-sm-8">
                        <Link to={`/image/${task.md5}`}>{task.md5}</Link>
                      </Col>
                    </Row>
                    <Row className="py-1 g-0">
                      <Col className="col-sm-2">Type</Col>
                      <Col className="col-sm-8">{task.task_type}</Col>
                    </Row>
                    <Row className="py-1 pb-2 border-bottom g-0">
                      <Col className="col-sm-2 d-flex align-items-center ">
                        Status
                      </Col>
                      <Col className="col-sm-8 flex-row ">
                        <div className="d-flex flex-row align-items-center">
                          <span className="me-2">{task.status}</span>
                        </div>
                      </Col>
                    </Row>
                    <Row className="py-1 pt-2 g-0">
                      <Col className="col-sm-2">Submitted at</Col>
                      <Col className="col-sm-8">
                        {new Date(task.submit_time).toLocaleString()}
                      </Col>
                    </Row>
                    <Row className="pt-1 pb-2 border-bottom  g-0">
                      <Col className="col-sm-2">Submitted by</Col>
                      <Col className="col-sm-8">
                        <Link to={`/user/${task.user_address}`}>
                          {task.user_address}
                        </Link>
                      </Col>
                    </Row>
                    <Row className="pt-1 pb-2 border-bottom  g-0">
                      <Col className="col-sm-2">Task taken by Node</Col>
                      <Col className="col-sm-8">
                        <span>
                          {task.node_address || "Task not taken by node"}
                        </span>
                      </Col>
                    </Row>
                    <Row className="py-1 pt-2 g-0">
                      <Col className="col-sm-2">Processing Started</Col>
                      <Col className="col-sm-8">
                        {task.process_started
                          ? new Date(task.process_started).toLocaleString()
                          : "Not Started"}
                      </Col>
                    </Row>
                    <Row className="py-1 g-0">
                      <Col className="col-sm-2">Processing Finished</Col>
                      <Col className="col-sm-8">
                        {task.process_finished
                          ? new Date(task.process_finished).toLocaleString()
                          : "Not Finished"}
                      </Col>
                    </Row>
                    <Row className="py-1 pb-2 border-bottom g-0">
                      <Col className="col-sm-2">Processing Time</Col>
                      <Col className="col-sm-8">
                        {processTime
                          ? processTime + " seconds"
                          : "Not Processed"}
                      </Col>
                    </Row>
                    {task.status === "Fail" ||
                    task.status === "DryRunFailed" ? (
                      <>
                        <Row className="pt-2  g-0">
                          <Col className="col-sm-2">Reason</Col>
                          <Col className="col-sm-8">
                            <div className="d-flex ">
                              <span>
                                {task.status_message ||
                                  "No error logs found for this task. Please contact the administrator."}
                              </span>
                            </div>
                          </Col>
                          <Col className="ms-2">
                            {task.internal_message ? (
                              <TaskLogsModal
                                taskStatus={task.status}
                                taskType={task.task_type}
                              />
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <></>
                    )}

                    <Row
                      className={
                        task.task_type === "Setup"
                          ? "g-0 pt-2"
                          : "g-0 py-2 border-bottom"
                      }
                    >
                      <Col className="col-sm-2">Task Fee</Col>
                      <Col className="col-sm-8">
                        {task.task_fee &&
                          convertAmount(task.task_fee, 0).toString()}{" "}
                        Credits
                      </Col>
                    </Row>

                    {/* Details for Proof and Deploy */}
                    {/* TODO: Can include details for failed tasks as well, need support from B/E */}
                    {task.task_type === "Prove" && (
                      <>
                        {task.status != "Pending" && (
                          <>
                            <Row className="pt-2  g-0">
                              <Col className="col-sm-2">Debug Logs</Col>
                              <Col className="col-sm-8">
                                {task.debug_logs ? (
                                  <div className="d-flex ">
                                    <pre className="debug-logs">
                                      {task.debug_logs}
                                    </pre>
                                  </div>
                                ) : (
                                  <div className="d-flex ">
                                    <pre className="debug-logs">
                                      No Debug logs found for this task.
                                    </pre>
                                  </div>
                                )}
                              </Col>
                            </Row>
                            <Row className="pt-2  g-0">
                              <Col className="col-sm-2">Guest Statics</Col>
                              <Col className="col-sm-8">
                                {task.guest_statics ? (
                                  <div className="d-flex ">
                                    <pre className="debug-logs">
                                      {task.guest_statics}
                                    </pre>
                                  </div>
                                ) : (
                                  <div className="d-flex ">
                                    No guest statics found for this task.
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </>
                        )}
                        <Row className="pt-2  g-0">
                          <Col className="col-sm-2">Proof Submit Mode</Col>
                          <Col className="col-sm-8">
                            {task.proof_submit_mode || ProofSubmitMode.Manual}
                          </Col>
                        </Row>
                        <Row className="pt-2  g-0">
                          <Col className="col-sm-2">Current Batch Status</Col>
                          <Col className="col-sm-8">
                            <div className="d-flex flex-column w-auto">
                              {showBatchDetailDropdown ? (
                                <>
                                  <div>
                                    {task.proof_submit_mode !==
                                    ProofSubmitMode.Auto
                                      ? "N/A"
                                      : (task.status === "Done" ||
                                            task.status === "Stale") &&
                                          task.auto_submit_status
                                        ? batchStatusMessage(
                                            task.auto_submit_status,
                                          )
                                        : "Task not completed"}
                                  </div>
                                  {getRound2BatchProofIds().map(
                                    (data, index) => {
                                      const chainName =
                                        appConfig.chain_info_list.find(
                                          (d) => d.chain_id === data.chain_id,
                                        )?.chain_name;
                                      return (
                                        <div
                                          key={index}
                                          className="w-auto my-2"
                                        >
                                          <Link
                                            to={`/batchproof/round2/${data.id}`}
                                          >
                                            Round 1 Proof - {chainName} -{" "}
                                            {data.id}
                                          </Link>
                                        </div>
                                      );
                                    },
                                  )}
                                  {getFinalBatchProofIds().map(
                                    (data, index) => {
                                      const chainName =
                                        appConfig.chain_info_list.find(
                                          (d) => d.chain_id === data.chain_id,
                                        )?.chain_name;
                                      return (
                                        <div
                                          key={index}
                                          className="w-auto my-2"
                                        >
                                          <Link
                                            to={`/batchproof/final/${data.id}`}
                                          >
                                            Round 2 Proof - {chainName} -{" "}
                                            {data.id}
                                          </Link>
                                        </div>
                                      );
                                    },
                                  )}
                                  <div
                                    onClick={() =>
                                      setShowBatchDetailDropdown(false)
                                    }
                                    className="text-decoration-underline"
                                    role="button"
                                  >
                                    Hide
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div>
                                    {task.proof_submit_mode !==
                                    ProofSubmitMode.Auto
                                      ? "N/A"
                                      : task.status === "Done" &&
                                          task.auto_submit_status
                                        ? batchStatusMessage(
                                            task.auto_submit_status,
                                          )
                                        : "Task not completed"}
                                  </div>
                                  {task.auto_submit_status !== undefined &&
                                    // Only show batch details if batching is pending round 2 or done
                                    (task.auto_submit_status ===
                                      AutoSubmitStatus.Round2 ||
                                      task.auto_submit_status ===
                                        AutoSubmitStatus.Batched ||
                                      task.auto_submit_status ===
                                        AutoSubmitStatus.RegisteredProof) && (
                                      <div
                                        className="text-decoration-underline cursor-pointer"
                                        onClick={() =>
                                          setShowBatchDetailDropdown(true)
                                        }
                                        role="button"
                                      >
                                        Show Batch Details
                                      </div>
                                    )}
                                </>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row className="pt-2  g-0">
                          <Col className="col-sm-2">Public Inputs</Col>
                          <Col className="col-sm-8">
                            <InputDisplay
                              inputs={task.public_inputs}
                            ></InputDisplay>
                          </Col>
                        </Row>
                        <Row className="pt-2  g-0">
                          <Col className="col-sm-2">Witness</Col>
                          <Col className="col-sm-8">
                            <InputDisplay
                              inputs={task.private_inputs}
                            ></InputDisplay>
                          </Col>
                        </Row>
                        {/* Only render if file bytes exists from b/e (default for old image is 0 bytes so no data) */}
                        {/* Empty host table [] will still be shown as file bytes is non-zero */}
                        {task.external_host_table.length > 0 && (
                          <Row className="pt-2  g-0">
                            <Col className="col-sm-2">External Host Table</Col>
                            <Col className="col-sm-8">
                              {external_host_table && (
                                <DownloadJsonButton
                                  // Wrap this in Uint8Array because it's "real" type is a number array.
                                  // There's no logic impact but the change to the API is big so it will have
                                  // to be fixed later.
                                  // https://delphinuslab.atlassian.net/browse/ZKWAS-361
                                  bytes={
                                    new Uint8Array(task.external_host_table)
                                  }
                                  filename="external_host_table"
                                  compression={task.compression}
                                ></DownloadJsonButton>
                              )}
                            </Col>
                          </Row>
                        )}
                        <Row className="pt-2  g-0">
                          <Col className="col-sm-2">Input Context</Col>
                          <Col className="col-sm-8">
                            {input_context && (
                              <ByteDisplay
                                content={input_context}
                              ></ByteDisplay>
                            )}
                          </Col>
                        </Row>
                        <Row className="pt-2  g-0">
                          <Col className="col-sm-2">Context Output</Col>
                          <Col className="col-sm-8">
                            {output_context && (
                              <ByteDisplay
                                content={output_context}
                              ></ByteDisplay>
                            )}
                          </Col>
                        </Row>
                        {(task.status === "Done" ||
                          task.status === "Stale") && (
                          <>
                            <Row className="pt-2  g-0">
                              <Col className="col-sm-2">
                                Single Proof Transcripts
                              </Col>
                              <Col className="col-sm-8">
                                {single_proof && (
                                  <ByteDisplay
                                    content={single_proof}
                                  ></ByteDisplay>
                                )}
                              </Col>
                            </Row>
                            <Row className="pt-2  g-0">
                              <Col className="col-sm-2">Instances</Col>
                              <Col className="col-sm-8">
                                {instances && (
                                  <ByteDisplay
                                    content={instances}
                                  ></ByteDisplay>
                                )}
                              </Col>
                            </Row>
                            <Row className="pt-2  g-0">
                              <Col className="col-sm-2">
                                <span>Batched Proof Transcripts</span>
                                <div className="mt-2 d-flex">
                                  {showVerifyModalButton && (
                                    <VerifyProofModal
                                      task={task}
                                    ></VerifyProofModal>
                                  )}
                                </div>
                              </Col>
                              <Col className="col-sm-8">
                                {aggregate_proof && (
                                  <ByteDisplay
                                    content={aggregate_proof}
                                  ></ByteDisplay>
                                )}
                              </Col>
                            </Row>
                            <Row className="pt-2  g-0">
                              <Col className="col-sm-2">Shadow Instances</Col>
                              <Col className="col-sm-8">
                                {shadowInstances && (
                                  <ByteDisplay
                                    content={shadowInstances}
                                  ></ByteDisplay>
                                )}
                              </Col>
                            </Row>
                            <Row className="pt-2  g-0">
                              <Col className="col-sm-2">Batch Instances</Col>
                              <Col className="col-sm-8">
                                {batchInstances && (
                                  <ByteDisplay
                                    content={batchInstances}
                                  ></ByteDisplay>
                                )}
                              </Col>
                            </Row>
                            <Row className="pt-2  g-0">
                              <Col className="col-sm-2">Aux Data</Col>
                              <Col className="col-sm-8">
                                {aux && (
                                  <ByteDisplay content={aux}></ByteDisplay>
                                )}
                              </Col>
                            </Row>
                          </>
                        )}
                      </>
                    )}
                    {task.task_type === "Deploy" && (
                      <Row className="pt-2  g-0">
                        <Col className="col-sm-2">Details</Col>
                        <Col className="col-sm-8">
                          <div className="d-flex ">
                            <span> View Deployment Info &nbsp;</span>
                            <ImageInfoModal md5={task.md5}></ImageInfoModal>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <>
          {loadingTask && (
            <Container className="loading-screen d-flex justify-content-center align-items-center">
              <Row>
                <Col>
                  <Spinner></Spinner>
                </Col>
              </Row>
            </Container>
          )}
          {!loadingTask && (
            <Container className="loading-screen d-flex justify-content-center align-items-center">
              <Row>
                <Col>No Task Found with this ID</Col>
              </Row>
            </Container>
          )}
        </>
      )}
    </>
  );
}

export const ByteDisplay = (props: { content: BN[] }) => {
  return (
    <div className="d-flex flex-column proof-detail-scroll ">
      {props.content.map((num: BN, index) => {
        return <span key={index}>{ZkWasmUtil.bnToHexString(num)}</span>;
      })}
    </div>
  );
};

export const HexDisplay = (props: { bytes: Uint8Array }) => {
  // split uint8array by 8 bytes (u64)
  let split = [];
  for (let i = 0; i < props.bytes.length; i += 8) {
    split.push(new Uint8Array(props.bytes.slice(i, i + 8)));
  }
  return (
    <div className="d-flex flex-column proof-detail-scroll ">
      {split.map((bytes, index) => {
        return <span key={index}>{hexlify(bytes)}&nbsp;</span>;
      })}
    </div>
  );
};

export const InputDisplay = (props: { inputs: string[] }) => {
  return (
    <div className="d-flex flex-column proof-detail-scroll ">
      {props.inputs.map((input: string, index) => {
        return <span key={index}>{input}&nbsp;</span>;
      })}
    </div>
  );
};
