import { Container, Row, Col, Card } from "react-bootstrap";
import { useGetNodeInfoQuery } from "../../data/apiSlice";
import { Link, useSearchParams } from "react-router-dom";
import { isValidNodeVersion } from "../NodeList";
import { useAppSelector } from "../../app/hooks";
import { selectConfig } from "../../data/statusSlice";

export default function NodeDetail() {
  let [searchParams, setSearchParams] = useSearchParams();

  let address = searchParams.get("address");

  const appConfig = useAppSelector(selectConfig);

  const {
    data: nodeStats,
    isFetching: isNodeStatsFetching,
    isError,
    isSuccess,
  } = useGetNodeInfoQuery(
    {
      start: 0,
      total: 1,
      address: address || "",
    },
    {
      skip: !address,
    },
  );

  const node = nodeStats?.data?.[0];

  // parse the performance track
  // It is a U256 hex string, need to count the 0 bits and 1 bits
  // 0 bits are the number of successful tasks
  // 1 bits are the number of failed tasks

  // let performanceTrack = node?.performance_track;
  // let successfulTasks = 0;
  // let failedTasks = 0;
  // if (performanceTrack) {
  //   let performanceTrackInt = BigInt(performanceTrack);
  //   let mask = BigInt(1);
  //   for (let i = 0; i < 256; i++) {
  //     if ((performanceTrackInt & mask) === BigInt(0)) {
  //       successfulTasks++;
  //     } else {
  //       failedTasks++;
  //     }
  //     mask = mask << BigInt(1);
  //   }
  // }

  return (
    <Container style={{ minHeight: "58vh" }}>
      <Container className="mt-5">
        <Row className="task-detail detail-max-width">
          {
            // Loading
            isNodeStatsFetching && <Col>Loading...</Col>
          }
          {
            // Error
            isError && <Col>Error Loading Node Information</Col>
          }
          {
            // Success
            isSuccess && node && (
              <Col>
                <Card className="tx-detail-body">
                  <Card.Header className="d-flex ">
                    Prover Node Statistics
                  </Card.Header>
                  {/* Task Properties */}
                  <Card.Body>
                    <Row className="py-1 g-0">
                      <Col className="col-sm-2">Address </Col>
                      <Col className="col-sm-8">{address}</Col>
                    </Row>
                    <Row className="py-1 g-0">
                      <Col className="col-sm-2">Latest Node Version Used </Col>
                      <Col className="col-sm-8">
                        {node.version_info
                          ? isValidNodeVersion(
                              node.version_info.version,
                              appConfig.server_version_info
                                .minimum_supported_node_version,
                            )
                            ? node.version_info.version
                            : `${node.version_info.version} - Update Required`
                          : "Update Required"}
                      </Col>
                    </Row>

                    <Row className="py-1 g-0">
                      <Col className="col-sm-2">Prover Level</Col>
                      <Col className="col-sm-8">{node.prover_level}</Col>
                    </Row>
                    <Row className="py-1 g-0">
                      <Col className="col-sm-2">Last Attempted Task</Col>
                      <Col className="col-sm-8">
                        {node.last_attempted_task ? (
                          <a
                            href={`/task/${node.last_attempted_task.task_id["$oid"]}`}
                          >
                            {node.last_attempted_task.task_id["$oid"]}
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </Col>
                    </Row>
                    <Row className="py-1 g-0">
                      <Col className="col-sm-2"></Col>
                      <Col className="col-sm-8">
                        {node.last_attempted_task ? (
                          <span>
                            {new Date(
                              node.last_attempted_task.timestamp,
                            ).toLocaleString()}
                          </span>
                        ) : null}
                      </Col>
                    </Row>
                    {/* <Row className="py-1 g-0">
                      <Col className="col-sm-2"></Col>
                      <Col className="col-sm-8">
                        Successful - {successfulTasks}
                      </Col>
                    </Row>
                    <Row className="py-1 g-0">
                      <Col className="col-sm-2"></Col>

                      <Col className="col-sm-8">Failed - {failedTasks} </Col>
                    </Row>
                    <Row className="py-1 g-0">
                      <Col className="col-sm-2 text-decoration-underline">
                        Statistics
                      </Col>
                    </Row> */}
                    <Row className="py-1 g-0">
                      <Col className="col-sm-2">Total Tasks Taken:</Col>
                      <Col className="col-sm-8">
                        {node.statistics?.total_tasks || 0}
                      </Col>
                    </Row>
                    <Row className="py-1 g-0">
                      <Col className="col-sm-2 ">
                        Total Successful Tasks Completed:
                      </Col>
                      <Col className="col-sm-8">
                        {node.statistics?.successful_tasks || 0}
                      </Col>
                    </Row>
                    <Row className="py-1 g-0">
                      <Col className="col-sm-8 text-decoration-underline">
                        Failure Statistics
                      </Col>
                    </Row>
                    <Row className="py-1 g-0">
                      <Col className="col-sm-2 ">Failed Tasks:</Col>
                      <Col className="col-sm-8">
                        {node.statistics?.failed_tasks}
                      </Col>
                    </Row>
                    <Row className="py-1 g-0">
                      <Col className="col-sm-2 ">Timed Out Tasks:</Col>
                      <Col className="col-sm-8">
                        {node.statistics?.timed_out_count || 0}
                      </Col>
                    </Row>
                    <Row className="py-1 g-0">
                      <Col className="col-sm-2 ">Last Timed Out Timestamp:</Col>
                      <Col className="col-sm-8">
                        {node.statistics?.last_timed_out
                          ? new Date(
                              node.statistics.last_timed_out,
                            ).toLocaleString()
                          : "N/A"}
                      </Col>
                    </Row>
                    <Row className="py-1 g-0">
                      <Col className="col-sm-8 text-decoration-underline">
                        Successful Setup Statistics
                      </Col>
                    </Row>
                    <Row className="py-1 g-0">
                      <Col className="col-sm-2 ">Latest Processing Time:</Col>
                      <Col className="col-sm-8">
                        {node.statistics?.setup_timing_stats?.latest_time_taken_secs
                          ?.toFixed(4)
                          .concat(" seconds") || "N/A"}
                      </Col>
                    </Row>
                    <Row className="py-1 g-0">
                      <Col className="col-sm-2 ">Latest Timestamp:</Col>
                      <Col className="col-sm-8">
                        {node.statistics?.setup_timing_stats?.latest_timestamp
                          ? new Date(
                              node.statistics.setup_timing_stats?.latest_timestamp,
                            ).toLocaleString()
                          : "N/A"}
                      </Col>
                    </Row>
                    <Row className="py-1 g-0">
                      <Col className="col-sm-8 text-decoration-underline">
                        Successful Proof Statistics
                      </Col>
                    </Row>
                    <Row className="py-1 g-0">
                      <Col className="col-sm-2 ">Latest Processing Time:</Col>
                      <Col className="col-sm-8">
                        {node.statistics?.proof_timing_stats?.latest_time_taken_secs
                          ?.toFixed(4)
                          .concat(" seconds") || "N/A"}
                      </Col>
                    </Row>
                    <Row className="py-1 g-0">
                      <Col className="col-sm-2 ">Latest Timestamp:</Col>
                      <Col className="col-sm-8">
                        {node.statistics?.proof_timing_stats?.latest_timestamp
                          ? new Date(
                              node.statistics.proof_timing_stats?.latest_timestamp,
                            ).toLocaleString()
                          : "N/A"}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            )
          }
        </Row>
      </Container>
    </Container>
  );
}
