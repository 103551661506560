import BN from "bn.js";
import { formatUnits } from "ethers";
import {
  BaseSubscriptionDuration,
  SubscriptionDuration,
} from "zkwasm-service-helper/dist/mjs/interface/interface";

export function compareBytes(a: Uint8Array, b: Uint8Array): boolean {
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export function convertAmount(
  balance: Uint8Array,
  decimals: number = 18,
): string {
  let amt = new BN(balance, 10, "le").toString();
  return formatUnits(amt, decimals);
}

export function hexToAmount(hex: string): string {
  //trim the 0x
  if (hex.startsWith("0x")) {
    hex = hex.slice(2);
  }
  let amt = new BN(hex, "hex").toString();
  return formatUnits(amt, "ether");
}

export function capNumber(num: string, significantDigits: number = 8) {
  if (num === undefined || num === null) return num;

  let parts = num.toString().split(".");
  if (parts[0].length >= significantDigits) {
    return parts[0];
  }
  let decimalsToShow =
    significantDigits - (parts[0] === "0" ? 0 : parts[0].length);

  if (parts[1] && parts[1].length > decimalsToShow) {
    //check number of leading 0s in parts[1]
    let zeros = 0;
    for (let i = 0; i < parts[1].length; i++) {
      if (parts[1][i] === "0") zeros++;
      else break;
    }
    let decimals = parts[1].substring(0, zeros + 1);

    return (
      parts[0] +
      "." +
      (zeros >= decimalsToShow - 1 && parts[0] === "0"
        ? decimals.length < parts[1].length
          ? decimals + "..."
          : decimals
        : parts[1].substring(0, decimalsToShow).length < parts[1].length &&
            parts[0] === "0"
          ? parts[1].substring(0, decimalsToShow) + "..."
          : parts[1].substring(0, decimalsToShow))
    );
  }
  return num;
}

export function calculateEndDate(duration: SubscriptionDuration): string {
  // if duration === Monthly, then add 30 days to current date
  let endDate = new Date();
  if (duration.base_duration === "Month") {
    endDate.setMonth(endDate.getMonth() + duration.multiplier);
  } else if (duration.base_duration === "Year") {
    endDate.setFullYear(endDate.getFullYear() + duration.multiplier);
  }
  // Format as DD/MM/YYYY, ensuring the month does not exceed 12
  return `${endDate.getDate()}/${
    endDate.getMonth() + 1
  }/${endDate.getFullYear()}`;
}

export function calculateExtendedDate(
  startingTimestamp: number,
  newDuration: SubscriptionDuration,
): string {
  let endDate = new Date(startingTimestamp * 1000);
  if (newDuration.base_duration === "Month") {
    endDate.setMonth(endDate.getMonth() + newDuration.multiplier);
  } else if (newDuration.base_duration === "Year") {
    endDate.setFullYear(endDate.getFullYear() + newDuration.multiplier);
  }
  // Correctly format the date without adding the multiplier again
  return `${endDate.getDate()}/${
    endDate.getMonth() + 1
  }/${endDate.getFullYear()}`;
}
