import "./style.scss";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Button, Container, Form, Modal } from "react-bootstrap";
import { TextInput } from "../../Inputs";
import { selectConfig } from "../../../data/statusSlice";
import { ethers, parseEther, parseUnits } from "ethers";
import { selectZkWasmServiceHelper } from "../../../data/endpoint";
import { addressAbbreviation } from "../../../utils/address";
import { withBrowserConnector } from "web3subscriber/src/client";
import { DelphinusBrowserConnector } from "web3subscriber/src/provider";
import { Row, Col } from "react-bootstrap";
import { ZkWasmUtil } from "zkwasm-service-helper";
import { loadUser } from "../../../data/accountSlice";

enum PaymentStatus {
  Idle,
  InProgress,
  Success,
  Failed,
}

export default function AddPayment() {
  const zkwasmHelper = useAppSelector(selectZkWasmServiceHelper);
  const appConfig = useAppSelector(selectConfig);
  const dispatch = useAppDispatch();
  const [amount, setAmount] = useState<string>("");
  const [transactionHash, setTransactionHash] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [withTxHash, setWithTxHash] = useState<boolean>(false);
  const [validAmt, setValidAmt] = useState<boolean>(false);

  const [inProgress, setInProgress] = useState<boolean>(false);
  const [status, setStatus] = useState<PaymentStatus>(PaymentStatus.Idle);

  const ValidateInput = (e: any) => {
    const re = /^\d+\.?\d*$/; //regex for decimal number with optional exponent
    // if value is not blank, then test the regex

    if (e.target.value === "" || re.test(e.target.value)) {
      //split the value by the decimal point
      let split = e.target.value.split(".");
      //get decimal part and check if too long
      let decimalPart = split[1];
      if (
        decimalPart &&
        decimalPart.length > appConfig.topup_token_data.decimals
      )
        return;
      setAmount(e.target.value);
      if (parseEther(e.target.value || "0") > 0) {
        setValidAmt(true);
        setMessage("");
        return;
      }
      setValidAmt(false);
    }
  };

  //Pop wallet in browser and save txhash
  const addPayment = async () => {
    setInProgress(true);
    setStatus(PaymentStatus.InProgress);
    //maybe let user choose target address in UI, otherwise we can set the address depending on the node
    //or we must wait for the node to return a valid address

    //with txhash option checked
    if (withTxHash) {
      console.log("with txhash");
      await submitTxHash(transactionHash);
      setStatus(PaymentStatus.Success);
      setInProgress(false);
      return;
    }
    let txhash = await sendTx();
    if (!txhash) return;
    await submitTxHash(txhash);
    dispatch(loadUser());
    setStatus(PaymentStatus.Success);
    setInProgress(false);
  };

  const sendTx = async () => {
    try {
      let receiverAddress = appConfig.receiver_address;
      if (!receiverAddress) {
        setMessage("No receiver address found");
        return;
      }
      try {
        await withBrowserConnector(
          async (connector: DelphinusBrowserConnector) => {
            const chainHex =
              "0x" + appConfig.topup_token_params.network_id.toString(16);
            await connector.switchNet(chainHex);
          },
        );
      } catch (e) {
        console.log(e);
        throw "Failed to switch networks";
      }
      let txHash = await withBrowserConnector(
        async (connector: DelphinusBrowserConnector) => {
          const account = await connector.getJsonRpcSigner();
          // todo: get decimals from contract
          const parsedAmount = parseUnits(
            amount,
            appConfig.topup_token_data.decimals,
          );

          setMessage(
            `Waiting for transaction to ${addressAbbreviation(
              receiverAddress,
              4,
            )} for amount ${amount} USDT`,
          );

          const contract = ZkWasmUtil.ERC20Contract(
            appConfig.topup_token_params.token_address,
            account,
          );

          const tx = await contract.transfer(receiverAddress, parsedAmount);

          setMessage("Waiting for confirmation: " + tx.hash);

          await tx.wait();
          return tx.hash;
        },
      );

      setTransactionHash(txHash || "");
      console.log(txHash, "Payment TXHash");
      setMessage("Transaction confirmed: " + txHash);
      return txHash;
    } catch (e) {
      console.log(e);
      setMessage("Transaction failed");
      setStatus(PaymentStatus.Failed);
      setInProgress(false);
    }
  };

  const submitTxHash = async (txHash: string) => {
    try {
      //Send the transaction hash to the backend
      await zkwasmHelper.addPayment({
        txhash: txHash,
      });

      setMessage(
        "Transaction successfully sent to server with hash: " + txHash,
      );
    } catch (error: any) {
      console.log(error);

      setMessage(error.message);
      setInProgress(false);
    }
  };

  return (
    <>
      <Row className="notice gx-0">
        Pay as you go for seamless and flexible access
      </Row>
      <Row className="align-items-end gx-0">
        <div className="topup-action-section">
          <Form className="w-100">
            <Form.Group className="mb-4">
              <Form.Label variant="dark" className="target-address">
                Payment address: {appConfig.receiver_address}{" "}
              </Form.Label>
            </Form.Group>

            <Form.Group className="mb-3">
              <div className="d-flex align-items-center">
                <Form.Label variant="dark" className="mb-3">
                  <span>Amount ({appConfig.topup_token_data.symbol}):</span>
                  {" 1 "}
                  {appConfig.topup_token_data.symbol}
                  {` = ${appConfig.topup_token_params.topup_conversion_rate} Credits `}
                </Form.Label>
                <Form.Group
                  className="d-flex ms-auto"
                  onChange={() => {
                    setWithTxHash(!withTxHash);
                  }}
                >
                  <div className="ms-auto d-flex ">
                    <Form.Check
                      className="me-2"
                      disabled={inProgress}
                    ></Form.Check>
                    <span className="checkbox-label">
                      Use a transaction hash
                    </span>
                  </div>
                </Form.Group>
              </div>

              <TextInput
                name="amount"
                type="text"
                disabled={withTxHash || inProgress}
                placeholder={`Enter an amount in ${appConfig.topup_token_data.symbol}`}
                multiple={false}
                value={amount}
                onChange={(e) => {
                  ValidateInput(e);
                }}
              ></TextInput>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label variant="dark" className="mb-3">
                Transaction Hash:{" "}
              </Form.Label>
              <TextInput
                className="mb-3"
                name="txhash"
                type="text"
                placeholder="Submit a deposit transaction hash"
                multiple={false}
                value={transactionHash}
                disabled={!withTxHash || inProgress}
                onChange={(e) => {
                  setTransactionHash(e.target.value);
                  //might want to apply a minimum send amount here and check in UI
                  if (e.target.value.length === 66) {
                    setMessage("");
                    return;
                  }
                }}
              ></TextInput>
            </Form.Group>
          </Form>

          {message && (
            <div className="message">
              <span>{message}</span>
            </div>
          )}

          {status !== PaymentStatus.Success && (
            <Button
              className="ms-auto confirm-btn"
              onClick={() => {
                addPayment();
              }}
              disabled={inProgress}
            >
              Confirm
            </Button>
          )}
          {status === PaymentStatus.Success && (
            <Button
              className="ms-auto confirm-btn"
              onClick={() => {
                // Reset the state
                setTransactionHash("");
                setAmount("");
                setMessage("");
                setWithTxHash(false);
                setValidAmt(false);
                setInProgress(false);
                setStatus(PaymentStatus.Idle);
              }}
            >
              OK
            </Button>
          )}
        </div>
      </Row>
    </>
  );
}
