import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import {
  AppConfig,
  DeployParams,
  ProvingParams,
  StatusState,
  QueryParams,
  AddImageParams,
  WithSignature,
  PaginationResult,
  Task,
  ResetImageParams,
} from "zkwasm-service-helper";

export const loadStatus = createAsyncThunk(
  "status/fetchStatus",
  async (query: QueryParams, thunkApi) => {
    let state = thunkApi.getState() as RootState;
    let helper = state.endpoint.zkWasmServiceHelper;
    let tasks = (await helper.loadTasks(query)) as PaginationResult<Task[]>;
    return tasks;
  },
);

export const loadStatistics = createAsyncThunk(
  "status/fetchStatistics",
  async (_, thunkApi) => {
    let state = thunkApi.getState() as RootState;
    let helper = state.endpoint.zkWasmServiceHelper;
    let st = await helper.loadStatistics();
    return st;
  },
);

export const loadConfig = createAsyncThunk(
  "status/fetchConfig",
  async (_, thunkApi) => {
    let state = thunkApi.getState() as RootState;
    let helper = state.endpoint.zkWasmServiceHelper;
    let config = await helper.queryConfig();
    return config;
  },
);

export const addNewWasmImage = createAsyncThunk(
  "status/addSetupTask",
  async (task: WithSignature<AddImageParams>, thunkApi) => {
    let state = thunkApi.getState() as RootState;
    let helper = state.endpoint.zkWasmServiceHelper;
    let response = await helper.addNewWasmImage(task);
    return response;
  },
);

export const addProvingTask = createAsyncThunk(
  "status/addProveTask",
  async (task: WithSignature<ProvingParams>, thunkApi) => {
    let state = thunkApi.getState() as RootState;
    let helper = state.endpoint.zkWasmServiceHelper;
    let response = await helper.addProvingTask(task);
    return response;
  },
);

export const addDeployTask = createAsyncThunk(
  "status/addDeployTask",
  async (task: WithSignature<DeployParams>, thunkApi) => {
    let state = thunkApi.getState() as RootState;
    let helper = state.endpoint.zkWasmServiceHelper;
    let response = await helper.addDeployTask(task);
    return response;
  },
);

export const addResetTask = createAsyncThunk(
  "status/addResetTask",
  async (task: WithSignature<ResetImageParams>, thunkApi) => {
    let state = thunkApi.getState() as RootState;
    let helper = state.endpoint.zkWasmServiceHelper;
    let response = await helper.addResetTask(task);
    return response;
  },
);

const initialState: StatusState = {
  tasks: [],
  loaded: false,
  statistics: {
    totalImages: 0,
    totalProofs: 0,
    totalDeployed: 0,
    totalTasks: 0,
  },
  config: {
    deployer_address: "",
    receiver_address: "",
    task_fee_list: {
      setup_fee: "",
      prove_fee: "",
      auto_submit_prove_fee_per_network: "",
    },
    topup_token_params: {
      topup_conversion_rate: null,
      token_address: "",
      network_id: 0,
    },
    topup_token_data: {
      symbol: "",
      decimals: 0,
    },
    chain_info_list: [],
    latest_server_checksum: new Uint8Array(),
    supported_auto_submit_network_ids: [],
    deployments: [],
    subscription_plans: [],
    server_version_info: {
      current_version: "",
      minimum_supported_node_version: "",
    },
  },
};

export const statusSlice = createSlice({
  name: "status",
  initialState,
  reducers: {
    updateState: (state, d) => {
      state.tasks = d.payload.tasks;
      state.loaded = d.payload.loaded;
    },
    sudo: (state, d) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewWasmImage.fulfilled, (_state, c) => {
        console.log("addImage", c.payload);
      })
      .addCase(loadStatus.fulfilled, (state, c) => {
        console.log("payload", c.payload);
        state.tasks = c.payload.data;
        state.loaded = true;
      })
      .addCase(loadStatistics.fulfilled, (state, c) => {
        state.statistics = c.payload;
      })
      .addCase(loadConfig.fulfilled, (state, c) => {
        state.config = c.payload;
      });
  },
});

export const { updateState, sudo } = statusSlice.actions;

export const selectTasks = (state: RootState) => state.status.tasks;
export const tasksLoaded = (state: RootState) => state.status.loaded;
export const selectStatistics = (state: RootState) => state.status.statistics;
export const selectConfig = (state: RootState) => state.status.config;

export default statusSlice.reducer;
