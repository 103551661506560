// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CustomCard.module.css */
.custom-card {
  /* Add default styles for the Card component here */
  background-color: #1e283000 !important;
  border: 2px solid #34575f !important;
  border-radius: 10px !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }

.custom-header,
.card-header {
  /* Add default styles for the Card.Header component here */
  background-color: rgba(0, 0, 0, 0) !important;
  border-bottom: none !important;
  align-items: center;
  margin: 0 auto;
  padding: 0px 15px !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;
  text-align: center; }

.custom-body {
  /* Add default styles for the Card.Body component here */
  padding: 0px 15px !important; }

.aqua {
  color: #72e9d9 !important; }

.light-blue {
  color: #8edef5 !important; }

.lighter-blue {
  color: #52e3e4 !important; }
`, "",{"version":3,"sources":["webpack://./src/components/Card/style.scss"],"names":[],"mappings":"AAAA,0BAAA;AAEA;EACE,mDAAA;EACA,sCAAsC;EACtC,oCAAoC;EACpC,8BAA8B;EAC9B,wEAE+B;EAC/B,qDAAqD,EAAA;;AAGvD;;EAGE,0DAAA;EACA,6CAA6C;EAE7C,8BAA8B;EAC9B,mBAAmB;EACnB,cAAc;EACd,4BAA4B;EAC5B,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,kBAAkB,EAAA;;AAGpB;EACE,wDAAA;EAEA,4BAA4B,EAAA;;AAG9B;EACE,yBAAyB,EAAA;;AAE3B;EACE,yBAAyB,EAAA;;AAE3B;EACE,yBAAyB,EAAA","sourcesContent":["/* CustomCard.module.css */\n\n.custom-card {\n  /* Add default styles for the Card component here */\n  background-color: #1e283000 !important;\n  border: 2px solid #34575f !important;\n  border-radius: 10px !important;\n  box-shadow:\n    0 1px 3px rgba(0, 0, 0, 0.12),\n    0 1px 2px rgba(0, 0, 0, 0.24);\n  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);\n}\n\n.custom-header,\n.card-header {\n  // Override bootstrap card style\n  /* Add default styles for the Card.Header component here */\n  background-color: rgba(0, 0, 0, 0) !important;\n\n  border-bottom: none !important;\n  align-items: center;\n  margin: 0 auto;\n  padding: 0px 15px !important;\n  font-family: \"Inter\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 34px;\n  text-align: center;\n}\n\n.custom-body {\n  /* Add default styles for the Card.Body component here */\n\n  padding: 0px 15px !important;\n}\n\n.aqua {\n  color: #72e9d9 !important;\n}\n.light-blue {\n  color: #8edef5 !important;\n}\n.lighter-blue {\n  color: #52e3e4 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
