interface Prop {
  inputs: Array<string>;
}
export function Inputs(prop: Prop) {
  return (
    <>
      {prop.inputs.map((input: string, i) => {
        return (
          <span key={`input-${i}`} className="badge rounded-pill">
            {input}
          </span>
        );
      })}
    </>
  );
}

interface TimeProp {
  timestr: string;
}

export function Time(prop: TimeProp) {
  let r = new Date();
  let t = new Date(Date.parse(prop.timestr));
  if (r.toLocaleDateString() === t.toLocaleDateString()) {
    return <>{t.toLocaleTimeString()}</>;
  } else {
    return <>{t.toLocaleDateString()}</>;
  }
}

export function replaceURLs(message: string) {
  let urlRegex = /http[s]?:\/\/[^\s]+[^. ]/g;

  const url = message.match(urlRegex);

  if (!url) return [message];

  let parts: any[] = message.split(urlRegex);

  for (let i = 0; i < url.length; i++) {
    let _url = new URL(url[i]);

    parts.splice(
      i * 2 + 1,
      0,
      <a href={`${_url.origin}`} target="_blank" rel="noreferrer">
        {_url.hostname}
      </a>,
    );
  }

  console.log(parts, "parts");

  //console.log(message);
  return parts;
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
